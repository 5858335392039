import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer, CreateDriver } from '../../..';
import './CreateCustomerModal.scss';

interface IUserModal {
  open: boolean;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setSelectedDriver?: any;
}

const CreateDriverModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setSelectedDriver
}) => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [clickOnSubmit, setClickOnSubmit] = useState<boolean>(false);
  const [newDriver, setNewDriver] = useState<any>(null);

  useEffect(() => {
    if (!open && setDefaultData) {
      setDefaultData(null);
    }
  }, [open]);

  useEffect(() => {
    if (newDriver) {
      if (newDriver.id && newDriver.value) {
        setUsersList([...usersList, newDriver]);
        setSelectedDriver(newDriver);
        setClickOnSubmit(false);
        setOpen(false);
        setNewDriver(null);
      }
    }
  }, [newDriver]);

  return (
    <Modal
      open={open}
      disabled={btnDisabled}
      onConfirm={() => setClickOnSubmit(true)}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="customer-modal"
      content={
        <CreateDriver
          createFromModal={true}
          setClickOnSubmit={setClickOnSubmit}
          clickOnSubmit={clickOnSubmit}
          setOpenModal={setOpen}
          setNewDriver={setNewDriver}
        />
      }
    />
  );
};

export default CreateDriverModal;
