import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './ModalDocument.scss';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useNavigate } from 'react-router';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  boxShadow: 24,
  p: 4,
};

export default function ModalDocument({
  changeOpenModalDocument,
  openModalDocument,
  document,
  load,
  changeOpenModalForm,
}: any) {
  const navigate = useNavigate();

  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onClickPrevious = () => {
    setPageNumber(pageNumber - 1);
  };

  const onClickNext = () => {
    setPageNumber(pageNumber + 1);
  };

  function onDocumentLoadSuccess(res: any) {
    if (res && res.numPages) {
      setNumPages(res.numPages);
    }
  }

  return (
    <div>
      <Modal
        keepMounted
        open={openModalDocument}
        onClose={changeOpenModalDocument}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="modal-document_pdf"
      >
        <Box sx={style} className="modal-document">
          <Typography
            id="keep-mounted-modal-title"
            className="modal-document_title"
            variant="h6"
            component="h2"
          >
            FreightLife Invoice Packet
            <div onClick={changeOpenModalDocument} className="modal-document_close"></div>
          </Typography>
          <div className={`documentContainerPdf ${numPages === 1 ? 'documentContainerPdf__single-page' : ''}`}>
            {document && <Document
              file={{
                url: document.file,
                httpHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} className="pdf-doc" />
            </Document>}

            {numPages > 1 && <div className="paginationContainerPdf">
              <p>
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </p>

              <div className="prevNextContainer">
                <span
                  onClick={() => (pageNumber > 1 ? onClickPrevious() : null)}
                  className={`${pageNumber <= 1 ? 'prevNextContainer__disabled' : ''}`}
                >
                  PREVIOUS
                </span>
                <span
                  onClick={() => (pageNumber < numPages ? onClickNext() : null)}
                  className={`${
                    pageNumber >= numPages ? 'prevNextContainer__disabled' : ''
                  }`}
                >
                  NEXT
                </span>
              </div>
            </div>}
          </div>
          <div className="modal-document_footer">
            <div className="modal-document_footer-title">
              You may edit only Detail Load, but BOL its a scan.
            </div>
            <Stack className="modal-document_footer-btns" spacing={2} direction="row">
              <Button className="btn-edit" size="large" variant="outlined" onClick={() => {
                changeOpenModalDocument();
                navigate(`/edit-load/${load.id}`)
              }}>
                EDIT
              </Button>
              <Button className="btn-sumb" size="large" variant="contained" onClick={() => {
                changeOpenModalDocument();
                changeOpenModalForm();
              }}>
                submission
              </Button>
            </Stack>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
