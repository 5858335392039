import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { UpdateTruckCompanyModel } from '../../models/truckCompany/truckCompany.model';

export const createCustomer = createAsyncThunk('createCustomer', async (data: any) => {
  const response = await api.post(
    `customers/${data.ownerType}/${data.id}/customer/create`,
    data,
  );
  return response.data;
});

export const updateCustomer = createAsyncThunk('updateCustomer', async (data: any) => {
  const response = await api.post(
    `customers/${data.ownerType}/${data.id}/customer/${data.customerId}/update`,
    data,
  );
  return response.data;
});

export const deleteCustomer = createAsyncThunk('deleteCustomer', async (data: any) => {
  const response = await api.post(
    `customers/${data.type}/${data.id}/customer/${data.customerId}/delete`,
    data,
  );
  return response.data;
});

export const getCustomersList = createAsyncThunk(
  'getCustomersList',
  async (data: any) => {
    let response = null;

    if (data.customer_type) {
      response = await api.get(
        `customers/${data.type}/${data.id}/list?customer_type=${encodeURIComponent(
          data.customer_type,
        )}`,
      );
    } else {
      response = await api.get(`customers/${data.type}/${data.id}/list`);
    }
    return response.data;
  },
);

export const getCustomerInfo = createAsyncThunk('getCustomer', async (data: any) => {
  const response = await api.get(
    `customers/${data.type}/${data.ownerId}/customer/${data.customerId}/get`,
  );
  return response.data;
});

interface driverState {
  carrierInfo: string | null;
  companyName: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  mailingAddress: string | null;
  mailingCity: string | null;
  mailingState: string | null;
  mailingZip: string | null;
  mcAuthority: string | null;
  taxId: string | null;
  dot: string | null;
  scacCode: string | null;
  isHazmat: string | null;
  officePhone: string | null;
  officeFax: string | null;
  cellPhone: string | null;
  errorMessage: string | null;
  logo: any;
  documents: [] | null;
  entityId: number | null;
  flNumber: string | null;
}

const initialState: driverState = {
  carrierInfo: null,
  companyName: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  mailingAddress: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  mcAuthority: null,
  taxId: null,
  dot: null,
  scacCode: null,
  isHazmat: null,
  officePhone: null,
  officeFax: null,
  cellPhone: null,
  errorMessage: null,
  logo: null,
  documents: [],
  entityId: null,
  flNumber: null,
};

export const customer = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      toast.success('Profile has been successfully changed!');

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  },
});

export default customer.reducer;
