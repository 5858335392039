
import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../Modal/Modal';
import './DeleteModalConfirmation.scss';

interface IUserModal {
  open: any;
  setOpen: any;
  loading?: boolean;
  deleteFunction: any;
}

const DeleteModalConfirmation: FC<IUserModal> = ({
  open,
  setOpen,
  loading,
  deleteFunction
}) => {

  return (
    <Modal
      open={open}
      disabled={loading}
      // onConfirm={() => setClickOnSubmit(true)}
      onConfirm={deleteFunction}
      loading={false}
      confirmTitle={"yes"}
      cancelTitle={"return"}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="customer-modal"
      content={
        <div
          className={`customizedInputContainer inputFlexOne`}
          style={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p className='confirmation'>Are you sure you want to delete user?</p>

        </div>
      }
    />
  );
};

export default DeleteModalConfirmation;
