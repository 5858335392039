import { useEffect, useState, useRef } from 'react';
import { useAppSelector } from '../../../../../hooks/global';
import { displayProtectedImage, displayImageSrc } from '../../../../../helpers';
import './ModalDocument.scss';

export function ModalDocument({
  closeOpenModalMap,
  isOpenModal,
  selectedObject,
  typeMediaOpen,
}: any) {
  const { load, loading } = useAppSelector(state => state.specificLoad);
  const [typeOpenMedia, setTypeOpenMedia] = useState<string>('');

  const handleClose = () => {
    closeOpenModalMap();
    const root: HTMLElement | null = document.querySelector('html');
    root ? (root.style.overflow = 'initial') : null;
    root ? (root.style.paddingRight = '0px') : null;
  };

  const skeletoon = (
    <div className="modal-document__row">
      <div className="modal-document__title">&nbsp;</div>
      <div className="modal-document__column">
        <div className="modal-document_item"></div>
        <div className="modal-document_item"></div>
        <div className="modal-document_item"></div>
        <div className="modal-document_item"></div>
      </div>
    </div>
  );

  useEffect(() => {
    if (selectedObject?.bols_scans.length > 0 && typeMediaOpen === 'bol-scan') {
      selectedObject.bols_scans.forEach((value: any) => {
        displayProtectedImage(value.id, value.thumbnail, token);
      });
    }
    if (selectedObject?.freight_pictures.length > 0 && typeMediaOpen === 'data-pack') {
      selectedObject.freight_pictures.map((value: any) => {
        displayProtectedImage(value.id, value.thumbnail, token);
      });
    }
  }, [load]);

  const token = localStorage.getItem('token');
  const rowReceivers = (
    <div className={`modal-document__row`}>
      <div className="modal-document__title">
      </div>
      <div className="modal-document__column">
        {selectedObject?.bols_scans.length > 0 && typeMediaOpen === 'bol-scan'
          ? selectedObject?.bols_scans.map((value: any) => {
              return (
                <div
                  key={value.id}
                  id={value.id}
                  className="modal-document_item"
                  onClick={() => setImage(value.file, value?.ext ? value.ext : 'pdf')}
                ></div>
              );
            })
          : ''}
        {selectedObject?.freight_pictures.length > 0 &&
        typeMediaOpen === 'data-pack'
          ? selectedObject.freight_pictures.map((value: any) => {
              return (
                <div
                  key={value.id}
                  id={value.id}
                  className="modal-document_item"
                  onClick={() => setImage(value.file, value?.ext ? value.ext : 'jpg')}
                ></div>
              );
            })
          : ''}
      </div>
    </div>
  );

  const setImage = (file: string, type: string) => {
    setTypeOpenMedia(type === 'pdf' ? type : 'jpg');
    displayImageSrc('place-image', file, token);
    setSingleImage();
  };
  const refContainer = useRef<HTMLDivElement>(null);

  const setSingleImage = () => {
    if (refContainer.current !== null) {
      refContainer.current.style.zIndex = '100000';
      refContainer.current.style.opacity = '1';
      refContainer.current.style.pointerEvents = 'initial';
    }
  };

  const sdeleteSingleImage = () => {
    if (refContainer.current !== null) {
      refContainer.current.style.zIndex = '-10000';
      refContainer.current.style.opacity = '0';
      refContainer.current.style.pointerEvents = 'none';
      const imageElement: HTMLElement | null = document.getElementById('place-image');
      if (imageElement) {
        imageElement.setAttribute('src', '');
      }
    }
  };

  const closeSingleImage = (event: any) => {
    if (
      !event.target.classList.contains('modal-document_item') &&
      !event.target.classList.contains('image-modal-document')
    ) {
      sdeleteSingleImage();
    }
  };

  return (
    <div
      onClick={event => closeSingleImage(event)}
      className={`popup-modal-document ${isOpenModal ? '_active' : ''}`}
    >
      <div ref={refContainer} className="singleWrap">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {typeOpenMedia === 'pdf' ? (
          <>
          <iframe id="place-image" frameBorder="0" className="bols-modal-document">
            Your browser does not support frames
          </iframe>
          <div onClick={event => closeSingleImage(event)} className="popup-data-puck__close closeBtnCustom"></div>
          </>
        ) : (
          <>
          <img id="place-image" className="image-modal-document" />
          <div onClick={event => closeSingleImage(event)} className="popup-data-puck__close closeBtnCustom"></div>
          </>
        )}
      </div>
      <div className="popup-modal-document__wrap">
        <div onClick={handleClose} className="popup-modal-document__close"></div>
        <div className="popup-modal-document__title">
          {typeMediaOpen === 'data-pack'
            ? 'data pack'
            : typeMediaOpen === 'bol-scan'
            ? 'bol scan'
            : ' - '}
        </div>
        <div className="modal-document__container">
          {loading ? (
            skeletoon
          ) : (
            <>
              {rowReceivers}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
