import React, { FC } from 'react';
import Pagination from '@mui/material/Pagination';
import './TablePagination.scss';

interface ITablePagination {
  showRow: number;
  maxRow: number;
  count: number;
  page?: number;
  setPage?: any;
}

const TablePagination: FC<ITablePagination> = ({ showRow, maxRow, count, page, setPage }) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="paginationTable">
      <div className="paginationTable__countPerPage">
        Displaying {showRow} of {maxRow}
      </div>

      <Pagination count={count} showFirstButton showLastButton page={page} onChange={handleChange}/>
    </div>
  );
};

export default TablePagination;
