import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { getReportsList } from '../../../store/reports/reports';
import Filters from './reportParts/Filters/Filters';
import TableComponent from './reportParts/Table';
import Loader from '../../common/Loader/Loader';
import { Pagination } from '../../controls';
import { IReportsList } from './interfaces/interface';
import './ReportsList.scss';

interface IUserManagement {
  partialBlockAccess?: boolean;
}

const ReportsList: FC<IUserManagement> = ({ partialBlockAccess }) => {
  const [amountPages, setAmountPages] = useState<number>(1);
  const [sort, setSort] = useState<null | string>(null);
  const [page, setPage] = useState<number>(1);
  const [reportsList, setReportsList] = useState<IReportsList | null>(null);
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const [reportType, setReportType] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { reportsListState, loading } = useAppSelector(state => state.reports);

  useEffect(() => {
    getList(page);
  }, [sort, sortDirection, dateTo, dateFrom, reportType]);

  useEffect(() => {
    if (reportsList && reportsList.data && reportsList.data.resource) {
      setAmountPages(reportsList.data.resource?.last_page ? reportsList.data.resource?.last_page : 1);
    }
  }, [reportsList, sort]);

  useEffect(()=> {
    setReportsList(reportsListState);
  }, [reportsListState])

  const changePage = (page: number) => {
    setPage(page);
    getList(page);
  };

  const changeSortType = (sortType: string | null) => {
    setSort(sortType);
    setSortDirection(sortDirection => (sortDirection === 'asc' ? 'desc' : 'asc'));
    setPage(1);
  };

  const getList = (page: number) => {
    dispatch(
      getReportsList({ page, sort, sortDirection, dateTo, dateFrom, reportType }),
    );
  };

  const changeReportType = (type: string) => setReportType(type)

  const changeDateFrom = (date: string) => setDateFrom(moment(date).format('YYYY-MM-DD'))

  const changeDateTo = (date: string) => setDateTo(moment(date).format('YYYY-MM-DD'))


  return (
    <>
      <Loader loading={loading} />
      <div className="reportsList mainContainer">
        <div className="reportsList__container">
          <div className="reportsList__body">
            <div className="reportsList__header">
              <div className="reportsList__title">
                <h1>Reports List</h1>
              </div>
            </div>
            <div className="componentHeader">
              <h2 className="componentHeader__title"></h2>
              <Filters
                changeReportType={changeReportType}
                changeDateFrom={changeDateFrom}
                changeDateTo={changeDateTo}
                reportType={reportType}
                dateFrom={dateFrom}
                dateTo={dateTo}
               />
            </div>
            <div className="mapView__main">
             {
             reportsList?.data?.resource?.data && 
             <TableComponent 
               changeSortType={changeSortType}
               arrayData={reportsList.data.resource.data}/>
             }
              <div className="pagination__body">
              <div className="totalList">
                Displaying &nbsp;
                {reportsList && reportsList.data ? !reportsList.data.resource.to ? 0 : reportsList.data.resource.to : 1}
                &nbsp; of &nbsp;
                {reportsList && reportsList.data ? reportsList.data.resource.total : 1}
              </div>
              {
                reportsList && reportsList.data && reportsList.data.resource && reportsList.data.resource.data && reportsList.data.resource.data.length > 0 ?
               <Pagination
                pageNumber={page}
                changePage={changePage}
                amountPages={amountPages}
              /> : null }
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsList;
