import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import './RadioButton.scss';

interface IRadioButton {
  label: string;
  className?: string;
  firstVariant?: string;
  secondVariant?: string;
  onHandleChangeValue?: any;
  defaultValue?: any;
  name?: string;
}

const RadioButton: FC<IRadioButton> = ({
  label,
  className,
  firstVariant,
  secondVariant,
  onHandleChangeValue,
  defaultValue,
  name,
}) => {
  const [selectedValue, setSelectedValue] = useState(firstVariant?.toLowerCase());
  const [selectedValueForUserList, setSelectedValueForUserList] = useState(firstVariant);

  const handleChange = (ev: any) => {
    setSelectedValue(ev.target.value);

    if (ev.target.value === firstVariant?.toLowerCase()) {
      onHandleChangeValue(1);
    } else {
      onHandleChangeValue(0);
    }
  };

  useEffect(() => {
    if (defaultValue === 1) {
      setSelectedValue(firstVariant?.toLowerCase());
      setSelectedValueForUserList(firstVariant);
    } else {
      setSelectedValue(secondVariant?.toLowerCase());
      setSelectedValueForUserList(secondVariant);
    }
  }, [defaultValue]);

  return (
    <div className={`customizedInputContainer ${className ? className : ''}`}>
      <div className="label">{label}</div>

      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        value={selectedValue}
        name="radio-buttons-group"
        onChange={handleChange}
      >
        {name !== 'users-list' ? (
          <>
            <FormControlLabel
              value={firstVariant?.toLowerCase()}
              control={<Radio />}
              label={firstVariant}
            />
            <FormControlLabel
              value={secondVariant?.toLowerCase()}
              control={<Radio />}
              label={secondVariant}
            />
          </>
        ) : (
          <FormControlLabel
            value={selectedValue}
            control={<Radio />}
            label={selectedValueForUserList}
          />
        )}
      </RadioGroup>
    </div>
  );
};

export default RadioButton;
