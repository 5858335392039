import React, { FC } from 'react';
import { Button } from '../../controls';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import './MapView.scss';

interface IUserManagement {
  partialBlockAccess?: boolean;
}

const MapView: FC<IUserManagement> = ({ partialBlockAccess }) => {
  return (
    <>
      <div className="mapView mainContainer">
        <div className="mapView__container">
          <div className="mapView__body">
            <div className="mapView__header">
              <div className="mapView__title">
                <h1>Loads</h1>
              </div>

              <div className="mapView__btnWrapper">
                <Button
                  onClick={() => null}
                  className="mapBtn"
                  variant="outlined"
                  title={'+ Add New Load'}
                />
              </div>
            </div>
            <div className="componentHeader">
              <h2 className="componentHeader__title">Full Map</h2>

              <div className="componentHeader__btnWrapper">
                <Button
                  title="Loads List"
                  Icon={<FormatListBulletedIcon color="primary" />}
                  onClick={() => null}
                  className="mapBtn"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="mapView__main">
              <img
                src={require('../../../assets/images/map-pic.png')}
                alt="map"
                width="100%"
                height="auto"
                className="mapPic"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapView;
