import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../service/api";
import { UpdateTruckCompanyModel } from "../../models/truckCompany/truckCompany.model";

export const createDriver = createAsyncThunk(
  "createDriver",
  async (data: any) => {
    const response = await api.post(
      `drivers/${data.type}/${data.id}/driver/create`,
      data
    );
    return response.data;
  }
);

export const updateDriver = createAsyncThunk(
  "updateDriver",
  async (data: any) => {
    const response = await api.post(`drivers/${data.driverId}/update`, data);
    return response.data;
  }
);

export const deleteDriver = createAsyncThunk(
  "deleteDriver",
  async (data: any) => {
    const response = await api.post(
      `drivers/${data.driverId}/delete`,
      data
    );
    return response.data;
  }
);

export const getDriversList = createAsyncThunk(
  "getDriversList",
  async (data: any) => {
    let requestString = `drivers/list?page=${data.page}`;

    if (data.firstName) {
      requestString += `&first_name=${data.firstName}`;
    }

    if (data.lastName) {
      requestString += `&last_name=${data.lastName}`;
    }

    if (data.email) {
      requestString += `&email=${encodeURIComponent(data.email)}`;
    }

    if (data.companyName) {
      requestString += `&company=${data.companyName}`;
    }
    const response = await api.get(requestString);
    return response.data;
  }
);

export const getDriverInfo = createAsyncThunk(
  "getDriver",
  async (data: any) => {
    const response = await api.get(`drivers/${data.driverId}/get`);
    return response.data;
  }
);

export const updateTruckCompanyInfo = createAsyncThunk(
  "updatingTruckCompanyInfo",
  async (truckCompany: UpdateTruckCompanyModel) => {
    const response = await api.post(
      `tc/${truckCompany.id}/update`,
      truckCompany
    );
    return response.data;
  }
);

interface driverState {
  carrierInfo: string | null;
  companyName: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  mailingAddress: string | null;
  mailingCity: string | null;
  mailingState: string | null;
  mailingZip: string | null;
  mcAuthority: string | null;
  taxId: string | null;
  dot: string | null;
  scacCode: string | null;
  isHazmat: string | null;
  officePhone: string | null;
  officeFax: string | null;
  cellPhone: string | null;
  errorMessage: string | null;
  logo: any;
  documents: [] | null;
  entityId: number | null;
  flNumber: string | null;
}

const initialState: driverState = {
  carrierInfo: null,
  companyName: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  mailingAddress: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  mcAuthority: null,
  taxId: null,
  dot: null,
  scacCode: null,
  isHazmat: null,
  officePhone: null,
  officeFax: null,
  cellPhone: null,
  errorMessage: null,
  logo: null,
  documents: [],
  entityId: null,
  flNumber: null,
};

export const driver = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(updateTruckCompanyInfo.fulfilled, (state, action) => {
      toast.success("Profile has been successfully changed!");

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  },
});

export default driver.reducer;
