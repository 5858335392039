import React, { FC } from 'react';
import { UploadPdfButton } from '../../../../controls';
import UploadFileButton from '../../../../controls/UploadFileButton/UploadFileButton';

interface IDetails {
  detailsOpened: boolean;
  setAssigmentNotice: any;
  assigmentNotice: any;
  isAnyFileUploded: boolean;
  setIsAnyFileUploded?: any;
  setW9: any;
  w9: any;
  setLiabilityInsurance: any;
  liabilityInsurance: any;
  setOperatingPermit: any;
  operatingPermit: any;
}

const DetailsTC: FC<IDetails> = ({
  detailsOpened,
  setAssigmentNotice,
  assigmentNotice,
  isAnyFileUploded,
  setIsAnyFileUploded,
  setW9,
  w9,
  setLiabilityInsurance,
  liabilityInsurance,
  setOperatingPermit,
  operatingPermit,
}) => {
  return (
    <>
      {detailsOpened && (
        <div>
          <div className="details__line-1">
            <UploadFileButton
              label="Notice of Assignment/Attention Accounts Payable"
              className="marginRight"
              changeValue={setAssigmentNotice}
              value={assigmentNotice}
            />
            <UploadFileButton label="W-9 Form" changeValue={setW9} value={w9} />
          </div>
          <div className="details__line-2">
            <UploadFileButton
              label="Certificate of Liability Insurance"
              className="marginRight"
              changeValue={setLiabilityInsurance}
              value={liabilityInsurance}
            />
            <UploadFileButton
              label="Operating Authority/Permit"
              changeValue={setOperatingPermit}
              value={operatingPermit}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(DetailsTC);
