import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button, Input } from '../../controls';
import './EmailVerify.scss';
import { useAppDispatch } from '../../../hooks/global';
import { emailVerify, fetchResetPassword } from '../../../store/user/user';
import { toast } from 'react-toastify';

const EmailVerify: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const verifyToken = localStorage.getItem('verify_token');
    if (verifyToken && verifyToken?.length) {
      dispatch(emailVerify(verifyToken)).then(res => {
        if (res.type === 'emailVerify/fulfilled') {
          localStorage.removeItem('verify_token');
          toast.success('Your email has been successfully verified!!!');

          setTimeout(() => {
            navigate('/sign-in');
          }, 2500);
        }
      });
    }
  }, []);

  return (
    <div className="setPassword l-popup">
      <div className="setPassword__container">
        <img
          src={require('../../../assets/images/icons/reset-password.png')}
          alt="set-password"
          className="setPassword__icon"
        />

        <h1 className="setPassword__title">Email Verification...</h1>
      </div>
    </div>
  );
};

export default EmailVerify;
