import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch, useAppSelector } from "../../../hooks/global";
import {
  getInvoicingDocument,
  getInvoicingList,
} from "../../../store/invoicing/invoicing";
import Loader from "../../common/Loader/Loader";
import Aside from "./parts/Aside/Aside";
import Filters from "./parts/Filters/Filters";
import TableComponent from "./parts/Table/Table";
import Pagination from "../../controls/Pagination/Pagination";
import ModalDocument from "./parts/ModalDocument/ModalDocument";
import ModalForm from "./parts/ModalForm/ModalForm";
import "./Invoicing.scss";
import { Bols } from "./parts/Bols/Bols";
import { Button, Portal } from "../../controls";
import moment from "moment";

interface IInvoicing {
  blockAccess?: boolean;
}

const Invoicing: FC<IInvoicing> = ({ blockAccess }) => {
  const [amountPages, setAmountPages] = useState<number>(1);
  const [sort, setSort] = useState<null | string>(null);
  const [page, setPage] = useState<number>(1);
  const [companyList, setCompanyList] = useState<any>([]);
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [currentCompany, setCurrentCompany] = useState<null | string>(null);
  const [dateFrom, setDateFrom] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [dateTo, setdateTo] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const [formattedDateFrom, setFormattedDateFrom] = useState<string | null>(
    null
  );
  const [formattedDateTo, setFormattedDateTo] = useState<string | null>(null);
  const [openModalDocument, setOpenModalDocument] = useState<boolean>(false);
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<boolean>(false);
  const [specificLoad, setSpecificLoad] = useState<null | number>(null);
  const [bolsScan, setBolsScan] = useState<boolean>(false);
  const [activeBols, setActiveBols] = useState<any>([]);
  const [selectedLoad, setSelectedLoad] = useState<any>(null);
  const { invoicingList, loading } = useAppSelector((state) => state.invoicing);
  const [selectedInvoiceDoc, setSelectedInvoiceDoc] = useState<any>(null);
  const [companyValue, setCompanyValue] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (invoicingList?.data?.resource) {
      setAmountPages(
        invoicingList.data.resource?.last_page
          ? invoicingList.data.resource.last_page
          : 1
      );
    }
  }, [invoicingList, sort]);

  useEffect(() => {
    getList(page);
  }, [sort, sortDirection, currentCompany, dateFrom, dateTo]);

  const onHandleSearch = () => {
    getList(page);
  };

  const changeSortType = (sortType: string | null) => {
    setSort(sortType);
    setSortDirection((sortDirection) =>
      sortDirection === "asc" ? "desc" : "asc"
    );
    setPage(1);
  };

  const getList = (page: number) => {
    dispatch(
      getInvoicingList({
        page,
        sort,
        sortDirection,
        formattedDateFrom,
        formattedDateTo,
        company: companyValue,
      })
    );
  };

  const changePage = (page: number) => {
    setPage(page);
    getList(page);
  };

  useEffect(() => {
    getList(page);
  }, []);

  const changeCurrentCompany = (event: SelectChangeEvent) =>
    setCurrentCompany(event.target.value as string);

  const changeDateTo = (newValue: string | null, type: string) => {
    if (newValue) {
      if (type !== 'reset') {
        setFormattedDateTo(moment(newValue).format('YYYY-MM-DD'));
      } else {
        setFormattedDateTo(null);
      }
    }
    setdateTo(newValue);
  };

  const changeDateFrom = (newValue: string | null, type: string) => {
    if (newValue) {
      if (type !== 'reset') {
        setFormattedDateFrom(moment(newValue).format('YYYY-MM-DD'));
      } else {
        setFormattedDateFrom(null);
      }
    }
    setDateFrom(newValue);
  };

  const changeOpenModalDocument = () =>
    setOpenModalDocument(!openModalDocument);

  const changeOpenModalForm = () => setOpenModalForm(!openModalForm);

  const getGeneratedDocument = (loadId: any) => {
    dispatch(getInvoicingDocument({ load_id: loadId })).then((res) => {
      if (res.type === "getInvoicingDocument/fulfilled") {
        setSelectedInvoiceDoc(res.payload.resource.file);
        setOpenModalDocument(true);
      }
    });
  };

  const changeActiveInvoice = (load: any) => {
    setSelectedLoad(load);
    const bolsScans: any = [];
    if (load.shippers?.length) {
      load.shippers.map((shipper: any) => {
        if (shipper.bols_scans?.length) {
          shipper.bols_scans.map((bol: any) => {
            bolsScans.push({
              thumbnail: bol.thumbnail,
              file: bol.file,
            });
          });
        }
      });
    }

    if (load.receivers?.length) {
      load.receivers.map((receiver: any) => {
        if (receiver.bols_scans?.length) {
          receiver.bols_scans.map((bol: any) => {
            bolsScans.push({
              thumbnail: bol.thumbnail,
              file: bol.file,
            });
          });
        }
      });
    }

    setActiveBols(bolsScans);
    setSelectedInvoice(true);
  };

  const changebolsScan = (type: boolean) => setBolsScan(type);

  const setOpenBolsScan = (load: number) => {
    setBolsScan(true);
    setSpecificLoad(load);
    const root: HTMLElement | null = document.querySelector("html");
    root ? (root.style.overflow = "hidden") : null;
    root ? (root.style.paddingRight = "17px") : null;
  };

  const resetAside = () => {
    setActiveBols([]);
    setSelectedLoad(null);
    setSelectedInvoice(false);
  };

  return (
    <>
      <Portal>
        <Bols
          changebolsScan={changebolsScan}
          bolsScan={bolsScan}
          loadId={specificLoad}
          typeMediaOpen="bol-scan"
        />
      </Portal>
      <ModalDocument
        changeOpenModalDocument={changeOpenModalDocument}
        changeOpenModalForm={changeOpenModalForm}
        openModalDocument={openModalDocument}
        document={selectedInvoiceDoc}
        load={selectedLoad}
      />
      <ModalForm
        changeOpenModalForm={changeOpenModalForm}
        openModalForm={openModalForm}
        load={selectedLoad}
        document_={selectedInvoiceDoc}
      />
      <Loader loading={loading} />
      <div className="invoicing mainContainer">
        <div className="invoicing__container">
          <div className="invoicing__header">
            <h1>Invoicing</h1>
          </div>
          <div className="invoicing__body">
            <div>
              <div className="componentHeader">
                <h1 className="componentHeader__title">Loads List</h1>
                {/* <div className="componentHeader__filters"> */}
                <div>
                  <Filters
                    changeDateTo={changeDateTo}
                    changeDateFrom={changeDateFrom}
                    changeCurrentCompany={changeCurrentCompany}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    currentCompany={currentCompany}
                    companyList={companyList}
                    companyValue={companyValue}
                    setCompanyValue={setCompanyValue}
                  />

                  <Button
                    title="SEARCH"
                    onClick={() => onHandleSearch()}
                    variant="contained"
                    className="searchBtn"
                  />
                </div>
              </div>

              {invoicingList && (
                <TableComponent
                  list={invoicingList}
                  changeSortType={changeSortType}
                  changeActiveInvoice={changeActiveInvoice}
                  setOpenBolsScan={setOpenBolsScan}
                />
              )}
              <div className="pagination__body">
                <div className="totalList">
                  Displaying &nbsp;
                  {invoicingList?.data
                    ? !invoicingList?.data?.resource?.to
                      ? 0
                      : invoicingList?.data?.resource?.to
                    : 1}
                  &nbsp; of &nbsp;
                  {invoicingList?.data
                    ? invoicingList?.data?.resource?.total
                    : 1}
                </div>
                {invoicingList?.data?.resource?.data?.length > 0 ? (
                  <Pagination
                    pageNumber={page}
                    changePage={changePage}
                    amountPages={amountPages}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* <Aside
          selectedInvoice={selectedInvoice}
          resetAside={resetAside}
          activeBols={activeBols}
          generateDocument={getGeneratedDocument}
          selectedLoad={selectedLoad}
        /> */}
      </div>
    </>
  );
};

export default Invoicing;
