import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey, mainGreen } from '../../../constants/colors';

interface ITab {
  value: string;
  label: string;
  id?: string;
}

interface ITabs {
  value: string;
  onChange: (ev: React.SyntheticEvent, newValue: string) => void;
  tabsList: Array<ITab>;
}

const useTabsStyles = makeStyles({
  root: {
    marginBottom: '25px',

    '& .MuiTab-root': {
      color: grey,
      fontSize: '16px',
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${grey}`,
      justifyContent: 'space-between',
    },
    '& .Mui-selected': {
      color: mainGreen,
      fontWeight: 'bold',
    },
    '& .MuiTabs-indicator': {
      height: '1px',
    },
  },
});

export const TabsComponent: React.FC<ITabs> = ({ value, onChange, tabsList }) => {
  const tabsClasses = useTabsStyles();

  return (
    <Tabs
      value={value}
      onChange={onChange}
      aria-label="roles"
      className={tabsClasses.root}
    >
      {tabsList.map((tabItem: ITab, index: number) => {
        return (
          <Tab
            value={tabItem.value}
            label={tabItem.label}
            key={index}
            id={tabItem.id ? tabItem.id : ''}
          />
        );
      })}
    </Tabs>
  );
};
