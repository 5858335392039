import { FC } from 'react';
import { useNavigate } from 'react-router';
import { TableBody, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Moment from 'react-moment';
import './Table.scss';

interface ITableComponent {
  arrayData: any,
  changeSortType: (value : string) => void
}

const TableComponent: FC<ITableComponent> = ({arrayData, changeSortType}) => {

  const navigate = useNavigate();
  const goEditLoad = (id: number) => navigate(`/edit-load/${id}`);  
  const goToReportPage = (id: number, type: string) => {
    switch(type.replace(/[^a-zа-яё]/gi, '')){
      case("AppModelsViolationReport") : 
        return navigate(`/violation-report/${id}`);
        break;
      case("AppModelsDamageReport"):
        return navigate(`/damage-reports/${id}`);
        break;
      case("AppModelsAccidentReport"):
        return navigate(`/accident-report/${id}`);
        break;
      default: 
      return navigate(`/reports-list`);  
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#373535',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '& td, th': {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    '&.MuiTableRow-root.MuiTableRow-head': {
      borderTop: '1px solid rgb(255 255 255 / 15%)',
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    '&.MuiTableContainer-root': {
      borderRadius: '0px',
    },
  }));

  return (
    <div className="dashboardMain reportList">
      <StyledTableContainer className="tableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <StyledTableHeadRow>
              <StyledTableCell>Load</StyledTableCell>
              <StyledTableCell>
                Date
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType("date")}
                />{' '}
              </StyledTableCell>
              <StyledTableCell>Report Type</StyledTableCell>
              <StyledTableCell>Driver Full Name</StyledTableCell>
              <StyledTableCell>
                Truck Сompany
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                  onClick={() => changeSortType("company")}
                />{' '}
              </StyledTableCell>
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {arrayData.map((row : any) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row" className="loadsCell" onClick={() => goEditLoad(row.load_id)}>
                  {row.load_number ? row.load_number : " "}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.date ? <Moment format="MM/DD/YYYY">{row.date}</Moment> : " - "}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className="reportCell" onClick={() => goToReportPage(row.owner_id, row.owner_type)}>
                  {row.type ? row.type : " - "}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.first_name ? row.first_name : ""} {row.last_name ? row.last_name : ""} 
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.company ? row.company : " - "}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default TableComponent;
