import React, { FC, useEffect, useState } from "react";
import { ContainedInput, Modal, Selection } from "../../../../controls";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { states } from "../../../../../constants/main";
import { useDispatch } from "react-redux";
import { getStatesList } from "../../../../../store/general/general";

interface IFilters {
  onlyByName?: boolean;
  className?: string;
  control?: any;
  selectedState?: any;
  setSelectedState?: any;
}

const Filters: FC<IFilters> = ({ onlyByName, className, control, selectedState, setSelectedState }) => {
  const dispatch: any = useDispatch();
  const [statesList, setStatesList] = useState(null);

  useEffect(() => {
    dispatch(getStatesList()).then((res: any) => {
      if (res.type === 'getStatesList/fulfilled') {
        if (res.payload.resource) {
          const states: any = [];
          res.payload.resource.map((state: any) => {
            states.push({
              value: state.code,
              label: state.title
            })
          })

          setStatesList(states);
        }
      }
    });
  }, [])

  return (
    <div className={className ? className : ""}>
      {onlyByName && (
        <div className="general__line-1">
          <ContainedInput
            label="First Name"
            className="marginRight"
            control={control}
            id="first_name"
          />
          <ContainedInput
            label="Last Name"
            className="marginRight"
            control={control}
            id="last_name"
          />
          <ContainedInput
            label="Company Name"
            className="marginRight"
            control={control}
            id="company_name"
          />
          <ContainedInput
            label="Email"
            control={control}
            id="email"
          />
        </div>
      )}

      {!onlyByName && (
        <div className="general__line-1">
          <ContainedInput
            label="Company Name"
            className="marginRight"
            control={control}
            id="company_name"
          />
          <ContainedInput
            label="City"
            className="marginRight"
            control={control}
            id="city"
          />
          <Selection
            label="State"
            className="customWidthState"
            items={statesList}
            name="mailing"
            setValue={setSelectedState}
            defaultValue={selectedState}
          />
          <ContainedInput
            label="Address Line 2"
            control={control}
            className="hiddenBlock inputFlexOne"
          />
        </div>
      )}
    </div>
  );
};

export default Filters;
