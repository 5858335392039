import React from 'react';
import Button from '@mui/material/Button';
import './Button.scss';

interface IButtonProps {
  title: string;
  id?: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
  Icon?: any;
  minWidth?: string | '100px';
  textColor?: string;
  variant?: 'text' | 'bold' | 'outlined' | 'contained' | undefined;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
}

export const ButtonComponent: React.FC<IButtonProps> = ({
  title,
  Icon,
  className,
  variant = 'outlined',
  onClick,
  minWidth,
  type,
  id,
  loading,
  disabled,
}) => {
  return (
    <Button
      variant={variant}
      disabled={loading || disabled ? true : false}
      startIcon={Icon}
      className={`${className} ${
        variant === 'contained' ? 'containedCustom' : ''
      } customBtn`}
      onClick={onClick}
      style={{ minWidth: minWidth }}
      type={type ? type : 'button'}
      id={id ? id : ''}
    >
      {title}
    </Button>
  );
};
