import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../service/api';
import { INotificate, IIncidentNotificate, ICounterNotificate } from '../../components/pages/Dashboard/interfaces/interface';

interface IGetNotificate {
  page: number,
  typeNotification: string
}

interface IChangeViewNotificate {
  typeNotification: string,
  arrayIds: {
    ids : number[]
  } 
}

export const getNotification = createAsyncThunk('getNotificate', async ({page, typeNotification} : IGetNotificate) => {
  const response = api.get(`notifications/${typeNotification}?page=${page}`);
  return response;
});

export const getNotificateCounters = createAsyncThunk('getNotificateCounters', async () => {
  const response = api.get(`notifications/counters`);
  return response;
});

export const changeViewednotification = createAsyncThunk(
  'changeViewednotification',
  async (data: IChangeViewNotificate) => {
    const response = await api.post(
      `notifications/${data.typeNotification}/set-seen`,
      data.arrayIds,
    );
    return response.data;
  },
);

interface IInitialState{
  loading: boolean,
  changednotificate: any,
  data : INotificate | IIncidentNotificate | null,
  counters: ICounterNotificate | null
}

const initialState: IInitialState = {
  loading: true,
  changednotificate: null,
  data : null,
  counters: null
};

export const notificate = createSlice({
  name: 'notificate',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getNotification.pending, state => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    
    builder.addCase(getNotificateCounters.fulfilled, (state, action) => {
      state.loading = false;
      state.counters = action.payload;
    });

    builder.addCase(changeViewednotification.fulfilled, (state, action) => {
      state.changednotificate = action.payload;
    });
  },
});

export default notificate.reducer;
