import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Checkbox, ContainedInput, Selection } from '../../../controls';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { shipper_types } from '../../../../constants/main';
import AutocompleteComponent from '../../../controls/AutocompleteSelection/Autocomplete';
import { ShoppingBasketRounded } from '@material-ui/icons';
import { getFullName } from '../../../../helpers';
import moment from 'moment';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IShipper {
  control: any;
  shipperId: number | string;
  isSelected: boolean;
  items: any;
  setModalOpen?: any;
  setShippersUsers: any;
  shippersUsers: any;
  openModal?: any;
  readOnly?: boolean;
  setSelectedUsers?: any;
  selectedUsers?: any;
}

const Shipper: FC<IShipper> = ({
  control,
  shipperId,
  isSelected,
  items,
  setModalOpen,
  setShippersUsers,
  shippersUsers,
  openModal,
  readOnly,
  setSelectedUsers,
  selectedUsers,
}) => {
  const [date, setDate] = useState<any>(null);
  const [time, setTime] = useState<any>(null);
  const [dateAndTime, setDateAndTime] = useState<any>(null);
  const [shipper, setShipper] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [showTimeChecked, setShowTimeChecked] = useState<any>(true);
  const [defaultAddress, setDefaultAddress] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [shipperInfo, setShipperInfo] = useState<any>(null);

  useEffect(() => {
    const date_ = moment(date);
    const time_ = moment(time);
    const correctDate_ = moment(date_, 'DD/MM/YYYY').format('DD/MM/YYYY');
    const correctTime_ = moment(time_, 'HH:mm').format('HH:mm');
    const dateTime = moment(correctDate_ + ' ' + correctTime_, 'DD/MM/YYYY HH:mm');
    const correctDateTime = dateTime.format('YYYY-MM-DD HH:mm');
    // setDateAndTime(new Date(correctDateTime));
    setDateAndTime(correctDateTime);
  }, [date, time]);

  useEffect(() => {
    if (dateAndTime) {
      if (shippersUsers.length) {
        const shippersUsers_: any = [];

        shippersUsers.map((shipper_: any) => {
          if (shipper_.index !== shipperId) {
            shippersUsers_.push(shipper_);
          } else {
            shippersUsers_.push({
              ...shipper_,
              dateAndTime: dateAndTime,
            });
          }
        });

        setShippersUsers(shippersUsers_);
      }
    }
  }, [dateAndTime]);

  useEffect(() => {
    if (shipper && shippersUsers.length) {
      if (shipper.id && setSelectedUsers) {
        if (!selectedUsers.length) {
          setSelectedUsers([
            {
              index: shipperId,
              customer_id: shipper.id,
            },
          ]);
        } else {
          setSelectedUsers((prev: any) => {
            const users_: any = [];
            if (prev.length) {
              prev.map((prevItem: any) => {
                if (prevItem.index === shipperId) {
                  users_.push({
                    index: shipperId,
                    customer_id: shipper.id,
                  });
                } else {
                  users_.push(prevItem);
                  users_.push({
                    index: shipperId,
                    customer_id: shipper.id,
                  });
                }
              });
            }

            return [...users_];
          });
        }
      }
      if (!shipper || shipper.id !== selectedCustomer?.id) {
        const shippersUsers_: any = [];

        shippersUsers.map((shipper_: any) => {
          if (shipper_.index !== shipperId) {
            shippersUsers_.push(shipper_);
          } else {
            shippersUsers_.push({
              ...shipper_,
              customer_id: shipper.id,
            });
          }
        });

        setShippersUsers(shippersUsers_);
      }
    }
  }, [shipper]);

  useEffect(() => {
    if (type && shippersUsers.length) {
      const shippersUsers_: any = [];

      shippersUsers.map((shipper_: any) => {
        if (shipper_.index !== shipperId) {
          shippersUsers_.push(shipper_);
        } else {
          shippersUsers_.push({
            ...shipper_,
            cargo_type: type,
          });
        }
      });

      setShippersUsers(shippersUsers_);
    }
  }, [type]);

  useEffect(() => {
    if (shippersUsers) {
      if (shippersUsers.length) {
        const shippersUsers_: any = [];

        shippersUsers.map((shipper_: any) => {
          if (shipper_.index !== shipperId) {
            shippersUsers_.push(shipper_);
          } else {
            shippersUsers_.push({
              ...shipper_,
              is_time_shown: showTimeChecked ? 1 : 0,
            });
          }
        });

        setShippersUsers(shippersUsers_);
      }
    }
  }, [showTimeChecked]);

  const handleChange = (newValue: any, type: string) => {
    if (shippersUsers?.length) {
      const shippersUsers_: any = [];

      if (type === 'date') {
        setDate(newValue);
        shippersUsers.map((shipper_: any) => {
          if (shipper_.index !== shipperId) {
            shippersUsers_.push(shipper_);
          } else {
            shippersUsers_.push({
              ...shipper_,
              date: newValue,
            });
          }
        });
      }

      if (type === 'time') {
        setTime(newValue);
        shippersUsers.map((shipper_: any) => {
          if (shipper_.index !== shipperId) {
            shippersUsers_.push(shipper_);
          } else {
            shippersUsers_.push({
              ...shipper_,
              time: newValue,
            });
          }
        });
      }

      setShippersUsers(shippersUsers_);
    }
  };

  useEffect(() => {
    if (items.length) {
      items.map((item: any) => {
        if (item.selected) {
          if (item.open) {
            if (
              item.open.customer_id === shipperId &&
              item.open.customer_type === 'shipper'
            ) {
              setSelectedCustomer(item);
              if (item.address) {
                setDefaultAddress(item.address);
              }
            }
          }
        }
      });
    }
  }, [items]);

  useEffect(() => {
    if (shippersUsers.length) {
      shippersUsers.map((shipper: any) => {
        if (shipper.index === shipperId) {
          setShipperInfo(shipper);
        }
      });
    }
  }, [shippersUsers]);

  useEffect(() => {
    if (!selectedCustomer?.id) {
      if (shipperInfo) {
        if (shipperInfo.customer) {
          setShowTimeChecked(shipperInfo.is_time_shown === 1 ? true : false);
          setSelectedCustomer({
            id: shipperInfo.customer.id,
            value: getFullName(
              shipperInfo.customer.first_name,
              shipperInfo.customer.last_name,
            ),
          });
          setDefaultAddress(shipperInfo.customer.address?.address);
          setDate(shipperInfo.date);
          setTime(shipperInfo.date);
        }
      }
    }
  }, [selectedCustomer, shipperInfo]);

  useEffect(() => {
    if (!type) {
      if (shipperInfo) {
        if (shipperInfo.cargo_type) {
          setType(shipperInfo.cargo_type);
        }
      }
    }
  }, [type, shipperInfo]);

  return (
    <>
      {shipperInfo && (
        <div className={`shipperForm ${!isSelected ? 'formHidden' : ''}`}>
          <div className="general__line-1">
            <AutocompleteComponent
              items={items}
              label="Shipper"
              className="inputFlexOne"
              setModalOpen={setModalOpen}
              setValueForInput={setShipper}
              defaultValue={selectedCustomer}
              customerType="shipper"
              customerId={shipperId}
              disabled={readOnly}
              control={control}
            />
            <ContainedInput
              label="Location"
              className="marginRight inputFlexOne"
              control={control}
              id={`location-${shipperId}`}
              readOnly
              defaultValue={shipper && shipper.address ? shipper.address : defaultAddress}
            />
            {readOnly ? (
              <ContainedInput
                label="Date"
                className="marginRight inputFlexOne"
                control={control}
                readOnly
                defaultValue={date ? moment(date).format('YYYY/MM/DD') : null}
              />
            ) : (
              <div className="marginRight birth inputFlexOne">
                <div className="label">Date</div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={date}
                    onChange={(newValue: any) => handleChange(newValue, 'date')}
                    renderInput={params => <TextField {...params} />}
                    disabled={readOnly}
                  />
                </LocalizationProvider>
              </div>
            )}

            {readOnly ? (
              <ContainedInput
                label="Show Time"
                className="marginRight inputFlexOne"
                control={control}
                readOnly
                defaultValue={date ? moment(date).format('hh:mm A') : null}
              />
            ) : (
              <div className="birth inputFlexOne">
                <div className="label showTimeLabel">
                  Show Time <Checkbox setCheckedValue={setShowTimeChecked} />
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    disabled={readOnly}
                    value={time}
                    onChange={(newValue: any) => handleChange(newValue, 'time')}
                    renderInput={params => (
                      <TextField
                        disabled={readOnly}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'hh:mm am/pm',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>

          <div className="general__line-1">
            <div className="inputsContainer inputFlexOne marginRight">
              <ContainedInput
                label="Description"
                className="inputFlexOne"
                control={control}
                id={`shipper.${shipperId}.description`}
                readOnly={readOnly}
                defaultValue={shipperInfo?.desc}
              />
            </div>
            <div className="inputsContainer inputFlexOne marginRight">
              <Selection
                label="Type"
                className="customWidthState inputFlexOne"
                items={shipper_types}
                name="shipper_type"
                setValue={setType}
                defaultValue={type}
                readOnly={readOnly}
                control={control}
              />
            </div>
            <div className="inputsContainer inputFlexOne">
              <ContainedInput
                label="Qty"
                className="marginRight inputFlexOne"
                control={control}
                id={`shipper.${shipperId}.qty`}
                readOnly={readOnly}
                defaultValue={shipperInfo?.qty}
              />
              <ContainedInput
                label="Weight"
                className="marginRight inputFlexOne"
                control={control}
                id={`shipper.${shipperId}.weight`}
                readOnly={readOnly}
                defaultValue={shipperInfo?.weight}
              />
            </div>
            <div className="inputsContainer inputFlexOne">
              <ContainedInput
                label="Value"
                control={control}
                className="inputFlexOne"
                type="price"
                id={`shipper.${shipperId}.value`}
                defaultValue={shipperInfo?.value ? shipperInfo.value : '0.00'}
                readOnly={readOnly}
              />
            </div>
          </div>

          <div className="general__line-1">
            <ContainedInput
              label="Shipping Notes"
              className="marginRight"
              control={control}
              id={`shipper.${shipperId}.shipping_notes`}
              readOnly={readOnly}
              defaultValue={shipperInfo?.notes}
            />
            <ContainedInput
              label="P.O. Numbers"
              className="marginRight"
              control={control}
              id={`shipper.${shipperId}.po_numbers`}
              readOnly={readOnly}
              defaultValue={shipperInfo?.po_numbers}
            />
            <ContainedInput
              label="Custom Freight Agent"
              className=""
              control={control}
              id={`shipper.${shipperId}.custom_fa`}
              readOnly={readOnly}
              defaultValue={shipperInfo?.custom_fa}
            />
          </div>
          <ContainedInput
            label=""
            className="hiddenBlock"
            control={control}
            id={`shipper.${shipperId}.shipperId`}
            readOnly
            defaultValue={shipper?.id}
          />
        </div>
      )}
    </>
  );
};

export default Shipper;
