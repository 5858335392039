import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { useAppDispatch } from '../../../../../hooks/global';
import { createDriver } from '../../../../../store/driver/driver';
import { updateTruckCompanyInfo } from '../../../../../store/truckCompany/truckCompany';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import {
  createNewUser,
  deleteUser,
  editUser,
  getUsersList,
} from '../../../../../store/user/user';
import Header from '../../../../common/Header/Header';
import Loader from '../../../../common/Loader/Loader';
import {
  Button,
  Tabs,
  ContainedInput,
  UploadButton,
  UploadPdfButton,
} from '../../../../controls';
import UploadFileButton from '../../../../controls/UploadFileButton/UploadFileButton';
import './CreateDriver.scss';
import General from './parts/General';
import { getFullName, getStringWithoutSymbols } from '../../../../../helpers';

interface ICreateDriver {
  createFromModal?: boolean;
  setClickOnSubmit?: any;
  clickOnSubmit?: boolean;
  setOpenModal?: any;
  setNewDriver?: any;
}

const CreateDriver: FC<ICreateDriver> = ({
  createFromModal,
  setClickOnSubmit,
  clickOnSubmit,
  setOpenModal,
  setNewDriver,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [generalOpened, setGeneralOpened] = useState(true);
  const [driverLicenseOpened, setDriverLicenseOpened] = useState(false);
  const [medCardOpened, setMedCardOpened] = useState(false);
  const [truckRegistrationOpened, setTruckRegistrationOpened] = useState(false);
  const [trailerRegistrationOpened, setTrailerRegistrationOpened] = useState(false);
  const [truckMaintenanceReportOpened, setTruckMaintenanceReportOpened] = useState(false);
  const [upcomingServiceNeedsOpened, setUpcomingServiceNeedsOpened] = useState(false);
  const [cargoInsuranceOpened, setCargoInsuranceOpened] = useState(false);
  const [taxTagsOpened, setTaxTagsOpened] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender, setGender] = useState(null);
  const [birth, setBirth] = useState(null);
  const [truckId, setTruckId] = useState<any>(null);
  const [trailerId, setTrailerId] = useState<any>(null);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [driverLicenseA, setDriverLicenseA] = useState({
    alias: 'driver_licence_a',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [driverLicenseB, setDriverLicenseB] = useState({
    alias: 'driver_licence_b',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [medCard, setMedCard] = useState({
    alias: 'med_card',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [truckRegistration, setTruckRegistration] = useState({
    alias: 'truck_reg',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [trailerRegistration, setTrailerRegistration] = useState({
    alias: 'trailer_reg',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [truckMaintenanceReport, setTruckMaintenanceReport] = useState({
    alias: 'maintenance_report',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [upcomingServiceNeeds, setUpcomingServiceNeeds] = useState({
    alias: 'upcoming_service',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [taxTags, setTaxTags] = useState({
    alias: 'tax_tag',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [cargoInsurance, setCargoInsurance] = useState({
    alias: 'cargo_insurance',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  useEffect(() => {
    if (clickOnSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [clickOnSubmit])

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');
    const userData = localStorage.getItem('user');

    const docs = [];

    if (driverLicenseA.file) {
      docs.push(driverLicenseA);
    }

    if (driverLicenseB.file) {
      docs.push(driverLicenseB);
    }

    if (medCard.file) {
      docs.push(medCard);
    }

    if (taxTags.file) {
      docs.push(taxTags);
    }

    if (cargoInsurance.file) {
      docs.push(cargoInsurance);
    }

    if (truckRegistration.file) {
      docs.push(truckRegistration);
    }

    if (trailerRegistration.file) {
      docs.push(trailerRegistration);
    }

    if (truckMaintenanceReport.file) {
      docs.push(truckMaintenanceReport);
    }

    if (upcomingServiceNeeds.file) {
      docs.push(upcomingServiceNeeds);
    }

    if (id && userData) {
      setSubmitLoading(true);
      const parsedData = JSON.parse(userData);

      const dataForCreate: any = {
        id,
        type:
          parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
            ? 'tc'
            : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
            ? 'fa'
            : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
            ? 'id'
            : null,
        user: {
          email: data.email,
        },
        profile: {
          first_name: data.first_name,
          last_name: data.last_name,
          gender: gender,
          birth: birth,
          truck_number: truckId?.value,
          trailer_number: trailerId?.value,
          truck_number_id: truckId?.id,
          trailer_number_id: trailerId?.id
        },
        address: {
          phone: getStringWithoutSymbols(data.phone),
          cell_phone: getStringWithoutSymbols(data.cell_phone),
        },
      };

      if (docs.length) {
        dataForCreate.documents = docs;
      }

      if (avatar.temp) {
        dataForCreate.avatar = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(createDriver(dataForCreate)).then(res => {
        if (res.type === 'createDriver/fulfilled') {
          toast.success('Driver was successfully created!');

          if (setClickOnSubmit && setOpenModal) {
            setNewDriver({
              id: res.payload.resource.id,
              value: getFullName(
                res.payload.resource.profile.first_name,
                res.payload.resource.profile.last_name,
              ),
            });
          } else {
            navigate('/users-management', {
              state: {
                tab: 'drivers',
              },
            });
          }
        } else {
          if (setClickOnSubmit) {
            setClickOnSubmit(false);
          }
          setSubmitLoading(false);
        }
      });
    }
  };

  return (
    <>
      {/* <Header /> */}

      <Loader loading={false} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Add New Driver</h1>
                </div>

                {!createFromModal && (
                  <Button
                    title="BACK"
                    Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                    onClick={() =>
                      navigate('/users-management', {
                        state: {
                          tab: 'drivers',
                        },
                      })
                    }
                    className="mapBtn"
                    variant="outlined"
                  />
                )}
              </div>

              <div className="createDriver__generalInfo">
                <div className="h2-title">
                  <h2 className="h2-title__text">General Information</h2>
                  <img
                    src={require('../../../../../assets/images/icons/expand-icon.png')}
                    alt="expand"
                    width="24px"
                    height="auto"
                    onClick={() => setGeneralOpened(!generalOpened)}
                    className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                  />
                </div>

                <General
                  generalOpened={generalOpened}
                  control={control}
                  setGender={setGender}
                  setBirth={setBirth}
                  birth={birth}
                  truckId={truckId}
                  setTruckId={setTruckId}
                  trailerId={trailerId}
                  setTrailerId={setTrailerId}
                />

                <hr className="horizontalLine fullHr" />
              </div>
            </div>

            <div className="createDriver__contactPersons">
              <div className="h2-title">
                <h2 className="h2-title__text">Driver License</h2>

                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDriverLicenseOpened(!driverLicenseOpened)}
                  className={`openBtn ${!driverLicenseOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {driverLicenseOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setDriverLicenseA}
                    value={driverLicenseA}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Med Card</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setMedCardOpened(!medCardOpened)}
                  className={`openBtn ${!medCardOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {medCardOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setMedCard}
                    value={medCard}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Truck Registration</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setTruckRegistrationOpened(!truckRegistrationOpened)}
                  className={`openBtn ${!truckRegistrationOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {truckRegistrationOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTruckRegistration}
                    value={truckRegistration}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Trailer Registration</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setTrailerRegistrationOpened(!trailerRegistrationOpened)}
                  className={`openBtn ${
                    !trailerRegistrationOpened ? 'openBtnOpened' : ''
                  }`}
                />
              </div>

              {trailerRegistrationOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTrailerRegistration}
                    value={trailerRegistration}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Tax Tags</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setTaxTagsOpened(!taxTagsOpened)}
                  className={`openBtn ${!taxTagsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {taxTagsOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTaxTags}
                    value={taxTags}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Cargo Insurance</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setCargoInsuranceOpened(!cargoInsuranceOpened)}
                  className={`openBtn ${!cargoInsuranceOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              {cargoInsuranceOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setCargoInsurance}
                    value={cargoInsurance}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Truck Maintenance Report</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() =>
                    setTruckMaintenanceReportOpened(!truckMaintenanceReportOpened)
                  }
                  className={`openBtn ${
                    !truckMaintenanceReportOpened ? 'openBtnOpened' : ''
                  }`}
                />
              </div>

              {truckMaintenanceReportOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setTruckMaintenanceReport}
                    value={truckMaintenanceReport}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="createDriver__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">Upcoming Service Needs</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() =>
                    setUpcomingServiceNeedsOpened(!upcomingServiceNeedsOpened)
                  }
                  className={`openBtn ${
                    !upcomingServiceNeedsOpened ? 'openBtnOpened' : ''
                  }`}
                />
              </div>

              {upcomingServiceNeedsOpened && (
                <div className="uploadFilesContainer">
                  <UploadFileButton
                    label=""
                    className="marginRight"
                    changeValue={setUpcomingServiceNeeds}
                    value={upcomingServiceNeeds}
                  />
                  <UploadFileButton
                    label=""
                    changeValue={setDriverLicenseB}
                    value={driverLicenseB}
                    className="hiddenBlock"
                  />
                </div>
              )}
            </div>

            {!setClickOnSubmit && (
              <Button
                title="SAVE ALL"
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                className="saveBtn"
                loading={submitLoading}
              />
            )}
          </div>

          {!createFromModal && (
            <div className="createDriver__userInfo">
              <div className="addNewPhotoBtn">
                <IconButton color="primary" aria-label="upload picture" component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={e => {
                      if (e && e.target && e.target.files) {
                        const fd = new FormData();
                        fd.append('file', e.target.files[0]);
                        dispatch(fetchUploadFile(fd)).then(res => {
                          if (res.type === 'uploadFile/fulfilled') {
                            setAvatar({
                              file: res.payload.file.fullpath,
                              temp: res.payload.file.filepath,
                            });
                          }
                        });
                      }
                    }}
                  />
                  <CameraAltOutlinedIcon />
                </IconButton>
              </div>
              <div className="userInfo__avatar">
                <img src={avatar.file} alt="" />
              </div>
              <div className="userInfo__role">Driver</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateDriver;
