import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import './ContainedInput.scss';
import InputMask from 'react-input-mask';

interface IUploadButton {
  label: string;
  className?: string;
  defaultValue?: string | null | any;
  id?: string;
  readOnly?: boolean;
  type?: string;
}

const CustomizedInput_: FC<IUploadButton> = ({
  label,
  className,
  defaultValue,
  readOnly,
  type,
}) => {
  return (
    <div className={`customizedInputContainer ${className ? className : ''}`}>
      <div className="label">{label}</div>

      {type !== 'phone' ? (
        <input
          className="customizedInputContainer__input"
          value={defaultValue}
          readOnly={readOnly ?? false}
        />
      ) : (
        <InputMask
          mask="+1\ (999) 999-9999"
          value={defaultValue}
          className="customizedInputContainer__input"
          readOnly={readOnly ?? false}
        />
      )}
    </div>
  );
};

export default CustomizedInput_;
