import React, { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer, CreateDriver, CreateID } from '../../..';
import './CreateCustomerModal.scss';

interface IUserModal {
  open: any;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setSelectedDispatcher?: any;
}

const CreateDispatcherModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setSelectedDispatcher,
}) => {
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [clickOnSubmit, setClickOnSubmit] = useState<boolean>(false);
  const [newDispatcher, setNewDispatcher] = useState<any>(null);

  useEffect(() => {
    if (!open.open && setDefaultData) {
      setDefaultData(null);
    }
  }, [open]);

  useEffect(() => {
    if (newDispatcher) {
      if (newDispatcher.id && newDispatcher.value) {
        setUsersList([...usersList, newDispatcher]);
        setSelectedDispatcher(newDispatcher);
        setClickOnSubmit(false);
        setOpen({open: false, customer_id: null, customer_type: null, customerIndex: null})
        setNewDispatcher(null);
      }
    }
  }, [newDispatcher]);

  return (
    <Modal
      open={open.open}
      disabled={btnDisabled}
      onConfirm={() => setClickOnSubmit(true)}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen({open: false, customer_id: null, customer_type: null, customerIndex: null})
      }}
      modalName="customer-modal"
      content={
        <CreateID
          createFromModal={true}
          setClickOnSubmit={setClickOnSubmit}
          clickOnSubmit={clickOnSubmit}
          setOpenModal={setOpen}
          setNewDispatcher={setNewDispatcher}
        />
        // <div className="general__line-1">
        //   <h2 className="contactPersonTitle">{contentTitle}</h2>

        //   <div className="fieldsInModal">
        //     <div className="fieldsInModal__field">
        //       <span className="fieldsInModal__label">First Name</span>
        //       <input
        //         className={`${firstNameError ? 'errorInput' : ''} marginRight`}
        //         onChange={ev => onHandleChange(ev, 'first_name')}
        //         defaultValue={defaultData ? defaultData.first_name : ''}
        //       />
        //       {firstNameError && <span className="errorText">{firstNameError}</span>}
        //     </div>
        //     <div className="fieldsInModal__field">
        //       <span className="fieldsInModal__label">Last Name</span>
        //       <input
        //         className={`${lastNameError ? 'errorInput' : ''} marginRight`}
        //         onChange={ev => onHandleChange(ev, 'last_name')}
        //         defaultValue={defaultData ? defaultData.last_name : ''}
        //       />
        //       {lastNameError && <span className="errorText">{lastNameError}</span>}
        //     </div>
        //     <div className="fieldsInModal__field">
        //       <span className="fieldsInModal__label">Email</span>
        //       <input
        //         className={`${emailError ? 'errorInput' : ''} marginRight`}
        //         onChange={ev => onHandleChange(ev, 'email')}
        //         defaultValue={defaultData ? defaultData.email : emailValue}
        //       />
        //       {emailError && <span className="errorText">{emailError}</span>}
        //     </div>
        //     <div className="fieldsInModal__field">
        //       <span className="fieldsInModal__label">Phone</span>
        //       <InputMask
        //         className={`${phoneError ? 'errorInput' : ''}`}
        //         onChange={ev => onHandleChange(ev, 'phone')}
        //         defaultValue={defaultData ? defaultData.phone : phoneValue}
        //         mask="+1\ (999) 999-9999"
        //       />
        //       {phoneError && <span className="errorText">{phoneError}</span>}
        //     </div>
        //   </div>
        // </div>
      }
    />
  );
};

export default CreateDispatcherModal;
