import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button, Input } from '../../controls';
import './ResetPassword.scss';
import { useAppDispatch } from '../../../hooks/global';
import { fetchRecoveryPassword } from '../../../store/user/user';

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);

    dispatch(
      fetchRecoveryPassword({
        username: data.userEmail.trim(),
      }),
    ).then(res => {
      if (res.type === 'recoveryPassword/fulfilled') {
        navigate('/sign-in');
      } else {
        setLoading(false);
      }
    });
  };

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <div className="resetPassword l-popup">
      <div className="resetPassword__container">
        <img
          src={require('../../../assets/images/icons/reset-password.png')}
          alt="reset-password"
          className="resetPassword__icon"
        />

        <h1 className="resetPassword__title">Reset Password</h1>

        <p className="resetPassword__p">
          Enter the email you used during registration to receive password reset
          instructions.
        </p>

        <Input
          name="Your Email Address"
          id="userEmail"
          type="text"
          className="resetPassword__field"
          control={control}
          isError={Boolean(errors.userEmail)}
          required={true}
          minLength={1}
          maxLength={50}
          withoutSpaces={true}
        />

        <div className="resetPassword__btnsContainer">
          <Button
            title="SEND RESET INSTRUCTIONS"
            className="resetPassword__btn"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            id="resetInstructions"
            loading={loading}
          />
        </div>

        <div className="authLinks">
          <span onClick={goToSignIn}>Sign-In Here</span>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
