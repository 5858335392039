import React, { FC } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './Pagination.scss';

interface IPaginate {
  amountPages : number,
  pageNumber: number,
  changePage : (value : number) => void
}
const PaginationButtons : FC<IPaginate> = ({ amountPages, pageNumber, changePage}) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    changePage(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination count={amountPages} page={pageNumber} onChange={handleChange} showFirstButton showLastButton />
    </Stack>
  );
}

export default PaginationButtons;