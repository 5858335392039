import { MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './Autocomplete.scss';
import { ContainedInput } from '..';

interface IUploadButton {
  label: string;
  className?: string;
  items: any;
  name?: string;
  setModalOpen?: any;
  setValueForInput?: any;
  defaultValue?: any;
  customerType?: string;
  customerId?: any;
  customerIndex?: any;
  moreModal?: boolean;
  valueId?: any;
  disabled?: boolean;
  control?: any;
  withoutAddNew?: boolean;
}

const AutocompleteComponent: FC<IUploadButton> = ({
  label,
  className,
  items,
  name,
  setModalOpen,
  setValueForInput,
  defaultValue,
  customerType,
  customerId,
  customerIndex,
  moreModal,
  valueId,
  disabled,
  control,
  withoutAddNew,
}) => {
  const filter = createFilterOptions<any>();
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (value) {
      if (setValueForInput) {
        setValueForInput(value);
      }
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue.id) {
        setValue(defaultValue);
      }
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (valueId) {
      if (valueId.id) {
        setValue({ id: valueId.id, value: valueId.number });
      }
    }
  }, [valueId]);

  return disabled ? (
    <ContainedInput
      label={label}
      className={`customizedInputContainer marginRight marginRight autocompleteContainer ${
        className ? className : ''
      }`}
      control={control}
      readOnly
      defaultValue={value?.value}
      id={value?.id === 'guest_driver' ? value?.id : ''}
    />
  ) : (
    <div
      className={`customizedInputContainer ${
        className ? className : ''
      } marginRight autocompleteContainer ${
        !items.length ? 'autocompleteContainer__hidden' : ''
      }`}
    >
      <div className="label">{label}</div>
      <Autocomplete
        disabled={disabled ? disabled : false}
        freeSolo
        handleHomeEndKeys
        selectOnFocus
        clearOnBlur
        disablePortal
        id="combo-box-demo"
        options={items}
        sx={{ width: 300 }}
        value={value}
        renderInput={params => <TextField {...params} />}
        getOptionLabel={(option: any) => option.value || ''}
        renderOption={(props, option) => {
          if (option) {
            if (option.id === 'Add new') {
              return (
                <li {...props} className="addFeatureContainer autocompleteItem">
                  <span className="addFeatureBtn">+ Add New</span>
                </li>
              );
            } else {
              return (
                <li {...props} className="autocompleteItem">
                  {option.value}
                </li>
              );
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option.value);
          if (inputValue !== '' && (!isExisting || options.length === 0 && !withoutAddNew)) {
            filtered.push({
              value: inputValue,
              id: 'Add new',
              title: `Add new "${inputValue}"`,
            });
          }

          return filtered;
        }}
        onChange={(event, newValue) => {
          const isValue = newValue ? true : false;
          const isExisting = items.some((item: any) => {
            return newValue === item.value || newValue?.value === item.value;
          });

          if (!isExisting && isValue) {
            if (setModalOpen) {
              setModalOpen({
                open: true,
                customer_id: customerId,
                customer_type: customerType,
                customerIndex: customerIndex,
              });
            }
          }
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setValue({
              title: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
      />
    </div>
  );
};

export default AutocompleteComponent;
