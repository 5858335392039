import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createDriverData_, createFreightAgentData } from "./testData";
import "./UsersList.scss";
import { useAppDispatch } from "../../../../hooks/global";
import { getDriversList } from "../../../../store/driver/driver";
import { useNavigate } from "react-router";
import Loader from "../../../common/Loader/Loader";
import { getMaskedPhone } from "../../../../helpers";
import { getFreightAgentsList } from "../../../../store/freightAgent/freightAgent";
import TablePagination from "../../../controls/TablePagination/TablePagination";
import Filters from "./Filters/Filters";
import { Button } from "../../../controls";
import { useForm } from "react-hook-form";

interface IFAList {
  partialBlockAccess?: boolean;
}

const FAList: FC<IFAList> = ({ partialBlockAccess }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = localStorage.getItem("user");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  const [users, setUsers] = useState<any>(null);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [countOnPage, setCountOnPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [companyNameFilter, setCompanyNameFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#373535",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "& td, th": {
      border: 0,
    },
  }));

  const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
    "&.MuiTableRow-root.MuiTableRow-head": {
      borderTop: "1px solid rgb(255 255 255 / 15%)",
    },
  }));

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    "&.MuiTableContainer-root": {
      borderRadius: "0px",
    },
  }));

  const getPhoneString = (
    firstPhone?: string | number,
    secondPhone?: string | number
  ) => {
    if (firstPhone && secondPhone) {
      return `${firstPhone + "\xa0\xa0" + "|" + "\xa0\xa0" + secondPhone}`;
    } else if (firstPhone && !secondPhone) {
      return firstPhone;
    } else if (!firstPhone && secondPhone) {
      return secondPhone;
    } else {
      return "";
    }
  };

  const searchByFilters = (data: any) => {
    setCompanyNameFilter(data.company_name);
    setCityFilter(data.city);
    setStateFilter(selectedState);
  };

  useEffect(() => {
    setListLoading(true);

    const dataForSearch = {
      page: pageNumber,
      city: cityFilter,
      companyName: companyNameFilter,
    };

    dispatch(getFreightAgentsList(dataForSearch)).then((res) => {
      if (res.type === "getFreightAgentsList/fulfilled") {
        if (res.payload.resource.data) {
          const faArray: any = [];
          res.payload.resource.data.map((user: any) => {
            faArray.push(
              createFreightAgentData(
                user.title,
                user.address.city,
                user.address.state?.title,
                user.mc_authority,
                user.scac,
                user.address.cell_phone,
                user.id,
              )
            );
          });

          setTotalCount(res.payload.resource.total);
          setCountOnPage(res.payload.resource.to);
          setItemsPerPage(res.payload.resource.per_page);
          setUsers(faArray);
          setListLoading(false);
        }
      }
    });
  }, [pageNumber, cityFilter, companyNameFilter, stateFilter]);

  const goToEdit = (id: string | number) => {
    if (partialBlockAccess) {
      return null;
    } else {
      navigate("/edit-freight-agent", { state: { id } });
    }
  };

  return (
    <>
      <div className="componentHeader componentHeader__userManagement">
        <Filters
          onlyByName={false}
          className="userManagement__filters"
          control={control}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
        />

        <Button
          title="SEARCH"
          onClick={handleSubmit(searchByFilters)}
          variant="contained"
          className="searchBtn"
        />
      </div>
      <div className="tableMain">
        <Loader loading={listLoading} />
        <StyledTableContainer className="tableContainer">
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <StyledTableHeadRow>
                <StyledTableCell align="left">Company Name</StyledTableCell>
                <StyledTableCell align="left">City</StyledTableCell>
                <StyledTableCell align="left">State</StyledTableCell>
                <StyledTableCell align="left">MC Authority #</StyledTableCell>
                <StyledTableCell align="left">SCAC Code</StyledTableCell>
                <StyledTableCell align="left">Office Phone</StyledTableCell>
                {/* <StyledTableCell align="left">
                Status{' '}
                <img
                  src={require('../../../../assets/images/icons/arrows-icon.png')}
                  alt="share"
                  width="18px"
                  height="18px"
                  className="sortBtn"
                />
              </StyledTableCell> */}
              </StyledTableHeadRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map((row: any, index: number) => (
                  <StyledTableRow
                    key={`${row.email}-${index}`}
                    onClick={() => goToEdit(row.id)}
                  >
                    <StyledTableCell component="th" scope="row" align="left">
                      {row.companyName}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className="greyCell alignLeft"
                    >
                      {row.city}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      className="greenCell alignLeft"
                    >
                      {row.state}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="nowrapText">
                      {row.mcAuthority}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="alignLeft">
                      {row.scac}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="alignLeft">
                      {getMaskedPhone(row.officePhone)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {Math.ceil(totalCount / itemsPerPage) > 1 && (
          <TablePagination
            showRow={countOnPage}
            maxRow={totalCount}
            count={Math.ceil(totalCount / itemsPerPage)}
            page={pageNumber}
            setPage={setPageNumber}
          />
        )}
      </div>
    </>
  );
};

export default FAList;
