import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import React, { FC } from 'react';
import './Filters.scss';

interface IFilter{
  reportType: string,
  dateFrom: string,
  dateTo: string,
  changeDateFrom: (date : string) => void,
  changeDateTo: (date : string) => void,
  changeReportType: (type : string) => void
}

const Filters: FC<IFilter> = ({changeDateFrom, changeDateTo, changeReportType, reportType, dateFrom, dateTo}) => {
  return (
    <div className="filtersContainer">
      <div className="selectDriverContainer filtersContainer__filter">
        <h3 className="helperInputText">Report Type</h3>
        <FormControl fullWidth size="small">
          <InputLabel id="select-label">Select type</InputLabel>
          <Select labelId="select-label" value={reportType} label="Age" onChange={(e) => changeReportType(e.target.value)}>
            <MenuItem value="ViolationReport">Violation Report</MenuItem>
            <MenuItem value="AccidentReport">Accident Report</MenuItem>
            <MenuItem value="DamageReport">Damage Report</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="filtersContainer__filter -datePicker">
        <h3 className="helperInputText">Date Range: From</h3>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={dateFrom}
              onChange={(e : any) => changeDateFrom(e)}
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>

      <div className="filtersContainer__filter -datePicker">
        <h3 className="helperInputText">Date Range: To</h3>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              label="Select date"
              inputFormat="MM/dd/yyyy"
              value={dateTo}
              onChange={(e: any) => changeDateTo(e)}
              renderInput={params => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>

      <img
        src={require('../../../../../assets/images/icons/dashboard-icon.png')}
        alt="Logo"
        width="36px"
        height="36px"
      />
    </div>
  );
};

export default Filters;
