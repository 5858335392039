import { FC, useEffect } from "react";
import Moment from "react-moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./Filters.scss";
import { ContainedInput, Input } from "../../../../controls";
import { useForm } from "react-hook-form";

interface IFilter {
  drivers?: { id: number; value: string }[];
  driver?: any;
  status?: any;
  dateValue?: any;
  changeDriver?: any;
  changeStatus?: any;
  changeDate?: any;
  changeTypeLoads?: any;
  typeLoads?: any;
  companyValue?: any;
  setCompanyValue?: any;
  setResetted?: any;
}

const Filters: FC<IFilter> = ({
  drivers,
  driver = " ",
  dateValue,
  status,
  changeStatus,
  changeDriver,
  changeDate,
  typeLoads,
  changeTypeLoads,
  companyValue,
  setCompanyValue,
  setResetted,
}) => {
  const { control } = useForm();
  useEffect(() => {}, [drivers, driver, status, dateValue, typeLoads]);

  return (
    <div className="filtersContainer">
      {setCompanyValue && <div className="selectStatusContainer filtersContainer__filter">
        <h3 className="helperInputText">Company Name</h3>

        <FormControl size="small" fullWidth>
          <input value={companyValue} onChange={(ev) => setCompanyValue(ev.target.value)} className="filterCompany"/>
        </FormControl>
      </div>}

      <div className="filtersContainer__filter filtersDatePicker">
        <h3 className="helperInputText">Date</h3>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              inputFormat="MM/dd/yyyy"
              value={dateValue ? dateValue : Date.now()}
              onChange={(e) => changeDate(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </div>

      <div className="selectStatusContainer filtersContainer__filter">
        <h3 className="helperInputText">Status</h3>

        <FormControl size="small" fullWidth>
          <InputLabel id="select-label">Select status</InputLabel>
          <Select
            labelId="select-label"
            value={status ? status : "all"}
            label="Age"
            onChange={(e) => changeStatus(e.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"in-progress"}>In progress</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"completed"}>Completed</MenuItem>
          </Select>
        </FormControl>
      </div>

      <img
        src={require("../../../../../assets/images/icons/dashboard-icon.png")}
        alt="Logo"
        width="36px"
        height="36px"
        style={{cursor: 'pointer'}}
        onClick={() => {
          setResetted(true);
          changeDriver('all');
          changeStatus('all');
          changeDate(null);
          changeTypeLoads('all');
          setCompanyValue('');
        }}
      />
    </div>
  );
};

export default Filters;
