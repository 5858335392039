import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import api from '../../service/api';

interface IGetLoad {
  currentCompany?: string | null,
  formattedDateFrom? : any,
  formattedDateTo? : any,
  sort? : string | null,
  sortDirection? : string,
  page?: number 
}

export const getInvoicingList = createAsyncThunk('getInvoicingList', async(data : any) => {
  
  const company = data.company && data.company !== null ? `&company=${data.company}` : "";
  const dateFrom = data.formattedDateFrom && data.formattedDateFrom !== null ? `&start_date=${data.formattedDateFrom}` : "";
  const dateTo = data.formattedDateTo && data.formattedDateTo !== null ? `&end_date=${data.formattedDateTo}` : "";
  const response = await api.get(`invoices/list?page=${data.page}&order_by=${data.sort}&order_dir=${data.sortDirection}${dateFrom}${dateTo}${company}`);
  return response;
});

export const getInvoicingDocument = createAsyncThunk('getInvoicingDocument', async(data : any) => {
  const response = await api.get(`invoices/generate?load_id=${data.load_id}`);
  return response.data;
});

export const sendDocumentToEmails = createAsyncThunk('sendDocumentToEmails', async(data : any) => {
  const response = await api.post(`invoices/${data.invoice_id}/send`, data);
  return response.data;
});

interface IInitialState{
  loading: boolean,
  invoicingList: any,
}

const initialState: IInitialState = {
  invoicingList: null,
  loading: false,
};

export const invoicing = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getInvoicingList.pending, state => {
        state.loading = true;
      })
      .addCase(getInvoicingList.fulfilled, (state, action) => {
        state.invoicingList = action.payload;
        state.loading = false;
      })
      .addDefaultCase(() => {});
  },
});

export default invoicing.reducer;
