import { Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { Button, Input, Tabs } from '../../controls';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import './SignUp.scss';
import { useNavigate } from 'react-router';
import { fetchSignUp } from '../../../store/user/user';
import { useAppDispatch } from '../../../hooks/global';
import { useForm } from 'react-hook-form';

const SignUp: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState('tc-admin');
  const [loading, setLoading] = useState(false);

  const rolesList = [
    { value: 'tc-admin', label: 'Truck company', id: 'truckCompany' },
    { value: 'fa-admin', label: 'Freight Agent', id: 'freightAgentCompany' },
    {
      value: 'id-admin',
      label: 'Independent Dispatcher',
      id: 'independetDispatcherCompany',
    },
  ];

  const handleClickShowPassword = (type: string) => {
    if (type === 'confirm') {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  const handleChangeRole = (ev: React.SyntheticEvent, newValue: string) => {
    setSelectedRole(newValue);
  };

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    dispatch(
      fetchSignUp({
        user: {
          email: data.userEmail,
          password: data.password,
          password_confirm: data.confirmPassword,
          role: selectedRole,
        },
        profile: {
          title: data.companyName,
        },
      }),
    ).then(res => {
      if (res.type === 'signup/fulfilled') {
        setTimeout(() => {
          navigate('/sign-in');
        }, 2000);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className="signUpContainer l-popup">
      <img
        src={require('../../../assets/images/main-logo.png')}
        alt="Logo"
        className="mainLogo"
      />

      <div className="signUpView">
        <h1 className="signUpTitle">Register Now</h1>

        <Tabs value={selectedRole} onChange={handleChangeRole} tabsList={rolesList} />

        <div className="signUpForm">
          <Stack spacing={1} className="authForm">
            <Input
              name="Company Name"
              id="companyName"
              type="text"
              className="textField"
              control={control}
              isError={Boolean(errors.companyName)}
              required={true}
              minLength={1}
              maxLength={50}
            />
            <Input
              name="Email"
              id="userEmail"
              type="text"
              className="textField"
              control={control}
              isError={Boolean(errors.userEmail)}
              required={true}
              minLength={1}
              maxLength={50}
            />
            <Input
              name="Password (minimum 8 characters)"
              id="password"
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              type="password"
              className="textField"
              control={control}
              isError={Boolean(errors.password)}
              required={true}
              minLength={8}
              maxLength={50}
            />
            <Input
              name="Confirm Password"
              id="confirmPassword"
              watch={watch}
              showPassword={showConfirmPassword}
              handleClickShowPassword={() => handleClickShowPassword('confirm')}
              type="password"
              className="textField"
              control={control}
              isError={Boolean(errors.confirmPassword)}
              required={true}
              minLength={8}
              maxLength={50}
            />
          </Stack>

          <div className="horizontalLine">
            <hr />
            <span>OR</span>
            <hr />
          </div>

          <div className="registrationBtns">
            <Button
              title="Sign-In with Facebook"
              Icon={<FacebookRoundedIcon color="warning" />}
              onClick={() => null}
              className="authBtn authBtn__fb"
              variant="outlined"
            />
            <Button
              title="Sign-In with Google"
              Icon={<GoogleIcon color="primary" />}
              onClick={() => null}
              className="authBtn"
              variant="outlined"
            />
          </div>

          <p className="agreeLinks">
            By clicking on Registration, you agree to our{' '}
            <span className="link">User Agreement</span> and{' '}
            <span className="link">Privacy Policy</span>
          </p>

          <div className="registrationBtns">
            <Button
              title="Register Now"
              onClick={handleSubmit(onSubmit)}
              type="submit"
              className="authBtn authBtn__login"
              variant="contained"
              id="registerNow"
              loading={loading}
            />
          </div>

          <div className="authLinks">
            <span>Already a Member,&nbsp;</span>
            <span onClick={goToSignIn} className="link">
              Sign-In Here
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
