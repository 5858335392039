// @flow
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../hooks/global';
import MapWidthMarkers from '../../Dashboard/parts/MapWidthMarkers/MapWidthMarkers';
import Loader from '../../../common/Loader/Loader';
import { ROUTES } from '../../../../router/routes';
import '../LoadsList.scss';

const FullMap: FC = () => {
  const { loadsList, loading } = useAppSelector(state => state.loads);
  const navigate = useNavigate();

  useEffect(() => {
    if (loadsList === null) navigate(ROUTES.LOADS);
  }, []);

  const goToList = () => {
    navigate(ROUTES.LOADS);
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="loadsList mainContainer">
        <div className="loadsList__container">
          <div className="loadsList__header">
            <h1>Loads</h1>
          </div>
          <div className="loadsList__body">
            <div className="componentHeader">
              <h2 className="componentHeader__title">
                Full Map
                <button className="btn-header _full-list" onClick={goToList}>
                  <span>Loads List</span>
                </button>
              </h2>
            </div>
            <MapWidthMarkers />
          </div>
        </div>
      </div>
    </>
  );
};

export default FullMap;
