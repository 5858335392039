import React, { FC, useEffect, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import { useParams } from 'react-router';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  getTrailersList,
  getTrucksList,
} from '../../../../../../store/load/load';
import { getSpecificLoad } from '../../../../../../store/specificLoad/specificLoad';
import { useAppDispatch } from '../../../../../../hooks/global';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { genders } from '../../../../../../constants/main';
import Loader from '../../../../../common/Loader/Loader';
import { ContainedInput, Selection } from '../../../../../controls';
import CreateTruckModal from './CreateTruckModal';
import CreateTrailerModal from './CreateTrailerModal copy';
import AutocompleteComponent from '../../../../../controls/AutocompleteSelection/Autocomplete';

interface IGeneral {
  generalOpened: boolean;
  generalInfo?: any;
  control: any;
  setGender: any;
  setBirth: any;
  birth: any;
  gender: string | null;
  truckId: any;
  setTruckId: any;
  trailerId: any;
  setTrailerId: any;
  tcId: any;
}

const General: FC<IGeneral> = ({
  generalOpened,
  generalInfo,
  control,
  setGender,
  truckId,
  setTruckId,
  trailerId,
  setTrailerId,
  setBirth,
  birth,
  gender,
  tcId,
}) => {
  const id = localStorage.getItem('owner_id');
  const entityId = localStorage.getItem('entity_id');
  const userData = localStorage.getItem('user');
  const dispatch = useAppDispatch();
  const params = useParams();
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  let type_: 'tc' | 'fa' | 'id' | null = null;

  if (parsedData) {
    type_ =
      parsedData.role === 'tc-admin' || parsedData.role === 'tc-user'
        ? 'tc'
        : parsedData.role === 'fa-admin' || parsedData.role === 'fa-user'
        ? 'fa'
        : parsedData.role === 'id-admin' || parsedData.role === 'id-user'
        ? 'id'
        : null;
  }
  const [trucksList, setTrucksList] = useState<any>([]);
  const [trailersList, setTrailersList] = useState<any>([]);
  const [loadInfo, setLoadInfo] = useState<any>(null);
  const [ownerId, setOwnerId] = useState<any>(null);
  const [readOnly, setReadOnly] = useState<any>(true);
  const [createTruckModal, setCreateTruckModal] = useState<boolean>(false);
  const [createTrailerModal, setCreateTrailerModal] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const id = localStorage.getItem('owner_id');
    
    if (id && type_) {
      const trucks: any = [];
      const trailers: any = [];

      const dataForDrivers: any = {
        id,
        type: type_,
      };


      dispatch(getTrucksList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrucksList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((truck: any) => {
                trucks.push({ id: truck.id, value: truck.number });
              });
              setTrucksList(trucks);
            }
          }
        }
      });

      dispatch(getTrailersList(dataForDrivers)).then((res: any) => {
        if (res.type === 'getTrailersList/fulfilled') {
          if (res.payload.data.resource.data) {
            if (res.payload.data.resource.data.length) {
              res.payload.data.resource.data.map((trailer: any) => {
                trailers.push({ id: trailer.id, value: trailer.number });
              });
              setTrailersList(trailers);
            }
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    console.log(tcId, 'tcIddd')
  }, [tcId])

  return (
    <>
      {generalOpened && generalInfo && (
        <>
         <Loader loading={submitLoading} />
          <div className="general__line-1">
            <ContainedInput
              label="First Name"
              className="marginRight"
              control={control}
              id="first_name"
              defaultValue={generalInfo.profile.first_name}
            />
            <ContainedInput
              label="Last Name"
              className="marginRight"
              control={control}
              id="last_name"
              defaultValue={generalInfo.profile.last_name}
            />
            <div className="marginRight birth">
              <div className="label">Date of Birth</div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={birth}
                  onChange={setBirth}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <Selection
              label="Gender"
              className="customWidthState"
              items={genders}
              setValue={setGender}
              defaultValue={gender}
            />
          </div>
          <div className="general__line-1">
            <ContainedInput
              label="Email"
              className="marginRight"
              control={control}
              id="email"
              defaultValue={generalInfo.email}
            />
            <ContainedInput
              label="Phone"
              className="marginRight"
              control={control}
              id="phone"
              defaultValue={generalInfo.address.phone}
              type="phone"
            />
            <ContainedInput
              label="Cell Phone"
              control={control}
              id="cell_phone"
              defaultValue={generalInfo.address.cell_phone}
              type="phone"
            />
          </div>
          <div className="general__line-1">
          <AutocompleteComponent
              items={[]}
              label="Truck Company"
              defaultValue={tcId}
              // valueId={trailerId}
              disabled={true}
              control={control}
              // setValueForInput={setTcId}
              withoutAddNew={true}
            />
          <ContainedInput
              label="Truck"
              className="marginRight"
              control={control}
              id=""
              defaultValue={truckId?.value}
              type=""
              readOnly={true}
            />
              <ContainedInput
              label="Trailer"
              control={control}
              id=""
              defaultValue={trailerId?.value}
              type=""
              readOnly={true}
            />
          </div>
            {createTruckModal && (
              <CreateTruckModal
                open={createTruckModal}
                setOpen={setCreateTruckModal}
                onChange={() => null}
                onSubmit={() => null}
                confirmTitle="ADD"
                cancelTitle="CANCEL"
                contentTitle=""
                formType="create"
                loading={submitLoading}
                usersList={trucksList}
                setUsersList={setTrucksList}
                setTruckId_={setTruckId}
              />
            )}

            {createTrailerModal && (
              <CreateTrailerModal
                open={createTrailerModal}
                setOpen={setCreateTrailerModal}
                onChange={() => null}
                onSubmit={() => null}
                confirmTitle="ADD"
                cancelTitle="CANCEL"
                contentTitle=""
                formType="create"
                loading={submitLoading}
                usersList={trailersList}
                setUsersList={setTrailersList}
                setTrailerId_={setTrailerId}
              />
            )}
          
        </>
      )}
    </>
  );
};

export default General;
