import React, { FC, useEffect, useState } from 'react';
import Loader from '../../common/Loader/Loader';
import MyProfileFA from './MyProfileFA/MyProfileFA';
import MyProfileID from './MyProfileID/MyProfileID';
import MyProfileTC from './MyProfileTC/MyProfileTC';

interface IProfile {
  tcSelector: any;
  idSelector: any;
  faSelector: any;
}

const MyProfile: FC<IProfile> = ({ tcSelector, idSelector, faSelector }) => {
  const [selectedProfileTab, setSelectedProfileTab] = useState('general_info');
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [detailsOpened, setDetailsOpened] = useState(true);
  const [generalOpened, setGeneralOpened] = useState(true);
  const [equipmentOpened, setEquipmentOpened] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [truckCompanyData, setTruckCompanyData] = useState<any | null>(null);
  const [independentDispatcherData, setIndependentDispatcherData] = useState<any | null>(
    null,
  );
  const [freightAgentData, setFreightAgentData] = useState<any | null>(null);

  const profileTabsList = [
    { value: 'general_info', label: 'General Info' },
    // { value: 'payment_details', label: 'Payment details' },
    // { value: 'payments_usage_history', label: 'Payments and usage history' },
  ];

  useEffect(() => {
    if (tcSelector) {
      setTruckCompanyData({ ...tcSelector });
    }
  }, [tcSelector]);

  useEffect(() => {
    if (idSelector) {
      setIndependentDispatcherData({ ...idSelector });
    }
  }, [idSelector]);

  useEffect(() => {
    if (faSelector) {
      setFreightAgentData({ ...faSelector });
    }
  }, [faSelector]);

  useEffect(() => {
    if (
      truckCompanyData?.entityId ||
      independentDispatcherData?.entityId ||
      freightAgentData?.entityId
    ) {
      setInfoLoading(false);
    }
  }, [truckCompanyData, independentDispatcherData, freightAgentData]);

  let parsedUserData: any = null;

  const userData = localStorage.getItem('user');

  if (userData) {
    parsedUserData = JSON.parse(userData);
  }

  useEffect(() => {
    setInfoLoading(true);

    if (parsedUserData?.email) {
      setEmail(parsedUserData.email);
      setCompanyName(parsedUserData.company_name);
      setRole(parsedUserData.role);
    }
  }, []);

  const handleChangeProfileTab = (ev: React.SyntheticEvent, newValue: string) => {
    setSelectedProfileTab(newValue);
  };

  return (
    <>
      {/* <Header /> */}
      <Loader loading={infoLoading} />
      {(role === 'tc-admin' || role === 'tc-user') && truckCompanyData.entityId ? (
        <MyProfileTC
          selectedProfileTab={selectedProfileTab}
          handleChangeProfileTab={handleChangeProfileTab}
          profileTabsList={profileTabsList}
          detailsOpened={detailsOpened}
          generalOpened={generalOpened}
          equipmentOpened={equipmentOpened}
          setDetailsOpened={setDetailsOpened}
          setGeneralOpened={setGeneralOpened}
          setEquipmentOpened={setEquipmentOpened}
          companyName={companyName}
          email={email}
          generalInfo={truckCompanyData}
        />
      ) : (role === 'id-admin' || role === 'id-user') &&
        independentDispatcherData.entityId ? (
        <MyProfileID
          selectedProfileTab={selectedProfileTab}
          handleChangeProfileTab={handleChangeProfileTab}
          profileTabsList={profileTabsList}
          detailsOpened={detailsOpened}
          generalOpened={generalOpened}
          equipmentOpened={equipmentOpened}
          setDetailsOpened={setDetailsOpened}
          setGeneralOpened={setGeneralOpened}
          setEquipmentOpened={setEquipmentOpened}
          companyName={companyName}
          email={email}
          generalInfo={independentDispatcherData}
        />
      ) : (role === 'fa-admin' || role === 'fa-user') && freightAgentData.entityId ? (
        <MyProfileFA
          selectedProfileTab={selectedProfileTab}
          handleChangeProfileTab={handleChangeProfileTab}
          profileTabsList={profileTabsList}
          detailsOpened={detailsOpened}
          generalOpened={generalOpened}
          setDetailsOpened={setDetailsOpened}
          setGeneralOpened={setGeneralOpened}
          companyName={companyName}
          email={email}
          generalInfo={freightAgentData}
        />
      ) : null}
    </>
  );
};

export default MyProfile;
