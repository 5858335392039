export function createDriverData(
  fullName,
  truckCompany,
  email,
  contactNumbers,
  status
) {
  return {
    fullName,
    truckCompany,
    email,
    contactNumbers,
    status,
  };
}

export function createDriverData_(
  fullName,
  companyName,
  email,
  contactNumbers,
  contactNumber1,
  contactNumber2,
  status,
  driverId,
  id,
  type,
  ownerId,
) {
  return {
    fullName,
    companyName,
    email,
    contactNumbers,
    contactNumber1,
    contactNumber2,
    status,
    driverId,
    id,
    type,
    ownerId,
  };
}

export function createTruckCompanyData(
  companyName,
  city,
  state,
  mcAuthority,
  scac,
  officePhone,
  id
) {
  return {
    companyName,
    city,
    state,
    mcAuthority,
    scac,
    officePhone,
    id
  };
}

export function createFreightAgentData(
  companyName,
  city,
  state,
  mcAuthority,
  scac,
  officePhone,
  id
) {
  return {
    companyName,
    city,
    state,
    mcAuthority,
    scac,
    officePhone,
    id
  };
}

export function createIndependentDispatcherData(
  companyName,
  city,
  state,
  mcAuthority,
  scac,
  officePhone,
  id
) {
  return {
    companyName,
    city,
    state,
    mcAuthority,
    scac,
    officePhone,
    id
  };
}

export function createCustomerData(
  customerName,
  id,
  billingAddress,
  phone,
  email
) {
  return {
    customerName,
    id,
    billingAddress,
    phone,
    email,
  };
}

export const rowsData = [
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
  createDriverData(
    "John Doe",
    `Company Name 1`,
    `sampleemail12346@gmail.net`,
    `+1 (918) 451-2936`,
    `Active`
  ),
];
