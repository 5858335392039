import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '../../controls';
import './NotFoundPage.scss';

interface INotFound {
  title: string;
}

const NotFoundPage: FC<INotFound> = ({ title }) => {
  const navigate = useNavigate();

  const goToProfiles = () => {
    navigate('/dashboard');
  };
  return (
    <div className="l-popup notFound">
      <div className="notFound__number">
        <span>4</span>
        <img
          src={require('../../../assets/images/icons/wheel_404.png')}
          alt="not-found"
          className="notFound__icon"
        />
        <span>4</span>
      </div>

      <p className="notFound__mainText">
        Sorry, but we can’t seem to find the page you are looking for.
      </p>

      <Button
        title={title}
        onClick={goToProfiles}
        variant="contained"
        className="notFound__btn"
      />
    </div>
  );
};

export default NotFoundPage;
