const getDomainUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'https://api.freightlife.dnmiss.com';
    case 'prod':
      return 'https://prod.example.com';
    case 'stage':
      return 'https://api.freightlife.dev.net-craft.com';
    default:
      return 'https://api.freightlife.dnmiss.com';
  }
};

export const getWebDomainUri = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'https://freightlife.dnmiss.com';
    case 'prod':
      return 'https://prod.example.com';
    case 'stage':
      return 'https://freightlife.dev.net-craft.com';
    default:
      return 'https://freightlife.dnmiss.com';
  }
};

export const DOMAIN_URI = getDomainUri();
export const API_URI = `${DOMAIN_URI}/api/admin/v1/`;
