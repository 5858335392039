import { Backdrop } from '@mui/material';
import React, { FC } from 'react';
import './Loader.scss';

interface ILoader {
  loading: boolean;
}

const Loader: FC<ILoader> = ({ loading }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <div className="spinner">
        <div />
        <div />
      </div>
    </Backdrop>
  );
};

export default Loader;
