import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Tabs } from "../../controls";
import "./UserManagement.scss";
import DriversList from "./parts/DriversList";
import FAList from "./parts/FAList";
import { useForm } from "react-hook-form";
import TCList from "./parts/TCList";
import CustomersList from "./parts/CustomersList";
import IDList from "./parts/IDList";

interface IMyMarker {
  text: string | number;
  tooltip: string | number;
  lng?: number;
  lat?: number;
}

interface IUserManagement {
  partialBlockAccess?: boolean;
}

const UserManagement: FC<IUserManagement> = ({ partialBlockAccess }) => {
  const userData = localStorage.getItem("user");
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [selectedProfileTab, setSelectedProfileTab] = useState("drivers");
  const [companyNameFilter, setCompanyNameFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState(null);
  const [firstNameFilter, setFirstNameFilter] = useState(null);
  const [lastNameFilter, setLastNameFilter] = useState(null);
  const [emailFilter, setEmailFilter] = useState(null);
  const [profileTabsList, setProfileTabsList] = useState([
    { value: "drivers", label: "Drivers" },
  ]);

  useEffect(() => {
    if (state) {
      if (state.tab) {
        setSelectedProfileTab(state.tab);
      }
    }
  }, [state]);

  useEffect(() => {
    if (userData) {
      const parsedData = JSON.parse(userData);

      if (parsedData.role === "tc-admin" || parsedData.role === "tc-user") {
        setProfileTabsList([
          { value: "freight_agents", label: "Freight Agents" },
          {
            value: "independent_dispatchers",
            label: "Independent Dispatchers",
          },
          { value: "drivers", label: "Drivers" },
        ]);
      } else if (
        parsedData.role === "id-admin" ||
        parsedData.role === "id-user"
      ) {
        setProfileTabsList([
          { value: "truck_companies", label: "Truck Companies" },
          { value: "freight_agents", label: "Freight Agents" },
          { value: "drivers", label: "Drivers" },
        ]);
      } else if (
        parsedData.role === "fa-admin" ||
        parsedData.role === "fa-user"
      ) {
        setProfileTabsList([
          { value: "truck_companies", label: "Truck Companies" },
          { value: "customers", label: "Customers" },
          { value: "drivers", label: "Drivers" },
        ]);
      } else if (parsedData.role === "admin") {
        setProfileTabsList([
          { value: "truck_companies", label: "Truck Companies" },
          {
            value: "independent_dispatchers",
            label: "Independent Dispatchers",
          },
          { value: "freight_agents", label: "Freight Agents" },
          { value: "drivers", label: "Drivers" },
        ]);
      }
    }
  }, []);

  const handleChangeProfileTab = (
    ev: React.SyntheticEvent,
    newValue: string
  ) => {
    setSelectedProfileTab(newValue);
  };

  const onHandleCreateUser = () => {
    if (selectedProfileTab === "drivers") {
      navigate("/create-driver");
    } else if (selectedProfileTab === "freight_agents") {
      navigate("/create-freight-agent");
    } else if (selectedProfileTab === "independent_dispatchers") {
      navigate("/create-independent-dispatcher");
    } else if (selectedProfileTab === "truck_companies") {
      navigate("/create-truck-company");
    } else if (selectedProfileTab === "customers") {
      navigate("/create-customer");
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="userManagement mainContainer">
        <div className="userManagement__container">
          <div className="userManagement__body">
            <div className="userManagement__userForm">
              <div className="userManagement__header">
                <div className="userManagement__title">
                  <h1>Users Management</h1>
                  <span>
                    {selectedProfileTab === "drivers"
                      ? "(Drivers)"
                      : selectedProfileTab === "freight_agents"
                      ? "(Freight Agents)"
                      : selectedProfileTab === "independent_dispatchers"
                      ? "(Independent Dispatchers)"
                      : selectedProfileTab === "truck_companies"
                      ? "(Truck Companies)"
                      : selectedProfileTab === "customers"
                      ? "(Customers)"
                      : ""}
                  </span>
                </div>

                <Tabs
                  value={selectedProfileTab}
                  onChange={handleChangeProfileTab}
                  tabsList={profileTabsList}
                />
              </div>

              <div>
                {selectedProfileTab === "drivers" ? (
                  <DriversList />
                ) : selectedProfileTab === "freight_agents" ? (
                  <FAList partialBlockAccess={partialBlockAccess} />
                ) : selectedProfileTab === "truck_companies" ? (
                  <TCList partialBlockAccess={partialBlockAccess} />
                ) : selectedProfileTab === "customers" ? (
                  <CustomersList partialBlockAccess={partialBlockAccess} />
                ) : selectedProfileTab === "independent_dispatchers" ? (
                  <IDList partialBlockAccess={partialBlockAccess} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
