import { createTheme } from '@mui/material';
import { blue, mainGreen, white } from '../../constants/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: mainGreen,
    },
    secondary: {
      main: white,
    },
    warning: {
      main: blue,
    },
  },
});
