import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { Button } from '../index';
import './Modal.scss';

interface IModal {
  onClose?: any;
  onCancel?: any;
  open: boolean;
  onConfirm: any;
  confirmTitle: string;
  modalTitle?: string;
  contentText?: string;
  content?: any;
  modalName?: string;
  cancelTitle?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const Modal: React.FC<IModal> = props => {
  const {
    onClose,
    open,
    onConfirm,
    confirmTitle,
    modalTitle,
    contentText,
    content,
    modalName,
    cancelTitle,
    onCancel,
    loading,
    disabled,
  } = props;

  const [content_, setContent] = useState<any>(null);

  useEffect(() => {
    if (content) {
      setContent(content);
    }
  }, [content]);

  const handleCancel = () => {
    if (onClose) {
      setContent(null);
      onClose();
    }
    if (onCancel) {
      setContent(null);
      onCancel();
    }
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      id="ringtone-menu"
      keepMounted
      className={
        modalName === 'pdf-modal' ||
        modalName === 'create-user-modal' ||
        modalName === 'document-modal' ||
        modalName === 'customer-modal' ||
        modalName === 'delete-item-modal'
          ? modalName
          : ''
      }
    >
      {modalTitle || onClose ? (
        <DialogTitle>
          {modalTitle ? <span>{modalTitle}</span> : null}
          <IconButton aria-label="delete" onClick={handleCancel}>
            <CloseRoundedIcon />
          </IconButton>
        </DialogTitle>
      ) : null}

      {contentText && <DialogContent dividers>{contentText}</DialogContent>}
      
      {content_ && content_}

      <DialogActions>
        {cancelTitle && (
          <Button
            onClick={() => handleCancel()}
            title={cancelTitle}
            variant="contained"
            className="cancelBtn"
          />
        )}
        <Button
          onClick={() => handleOk()}
          title={confirmTitle}
          variant="contained"
          loading={loading}
          disabled={disabled}
        />
      </DialogActions>
    </Dialog>
  );
};
