import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/global';
import { fetchUploadFile } from '../../../store/uploadFile/uploadFile';
import './UploadPdfButton.scss';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Modal } from '../../controls';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { displayProtectedImage } from '../../../helpers';

interface IUploadButton {
  label: string;
  className?: string;
  changeValue?: any;
  value?: any;
  isAnyFileUploded?: boolean;
  setIsAnyFileUploded?: any;
}

const UploadPdfButton: FC<IUploadButton> = ({
  label,
  className,
  changeValue,
  value,
  isAnyFileUploded,
  setIsAnyFileUploded,
}) => {
  const dispatch = useAppDispatch();

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [openFile, setOpenFile] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  function onDocumentLoadSuccess(res: any) {
    if (res && res.numPages) {
      setNumPages(res.numPages);
    }
  }

  const onOpenFileHandler = () => {
    if (value.fullpath) {
      setOpenFile(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (value.preview && token) {
      displayProtectedImage(value.alias, value.preview, token);
    }
  }, [value.preview]);

  const onClickPrevious = () => {
    setPageNumber(pageNumber - 1);
  };

  const onClickNext = () => {
    setPageNumber(pageNumber + 1);
  };

  const onHandleChange = (e: any) => {
    if (!value.fullpath) {
      setFileLoading(true);

      if (e && e.target && e.target.files) {
        const fd = new FormData();
        fd.append('file', e.target.files[0]);
        dispatch(fetchUploadFile(fd)).then(res => {
          setTimeout(() => setFileLoading(false), 2000);

          if (res.type === 'uploadFile/fulfilled') {
            changeValue({
              ...value,
              delete: 0,
              file: res.payload.file.filepath,
              title: res.payload.file.original_name,
              preview: res.payload.file.thumbnail,
              fullpath: res.payload.file.fullpath,
            });
            setIsAnyFileUploded(true);
          }
        });
      }
    }
  };

  const onDelete = () => {
    if (value.fullpath) {
      changeValue({
        ...value,
        delete: 1,
        file: null,
        title: null,
        preview: null,
        fullpath: null,
      });
    }
  };

  return (
    <div className={`uploadContainer ${className ? className : ''}`}>
      <div className="label">
        <span>{label}</span>
        {value.fullpath && (
          <div className="deleteFileContainer" onClick={onDelete}>
            <span>Delete</span>
            <CancelRoundedIcon />
          </div>
        )}
      </div>

      <div
        className={`uploadBtnContainer ${
          isAnyFileUploded ? 'uploadBtnContainer__uploaded' : ''
        }`}
        onClick={onOpenFileHandler}
      >
        {value.preview && <div className="uploadedFileContainer" id={value.alias}></div>}

        {!fileLoading ? (
          <IconButton color="primary" aria-label="upload picture" component="label">
            {!value.fullpath && (
              <input
                hidden
                accept="application/pdf,application/vnd.ms-excel"
                type="file"
                onChange={onHandleChange}
              />
            )}
            <img
              src={require('../../../assets/images/icons/upload-pdf-icon.png')}
              className="notHover"
            />
            <img
              src={require('../../../assets/images/icons/upload-pdf-icon-hover.png')}
              className="hover"
            />
          </IconButton>
        ) : (
          <div className="spinner">
            <div />
            <div />
          </div>
        )}
      </div>

      {value.fullpath && (
        <Modal
          open={openFile}
          modalName="pdf-modal"
          onConfirm={() => setOpenFile(false)}
          confirmTitle="BACK"
          content={
            <>
              <Document
                file={{
                  url: value.fullpath,
                  httpHeaders: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
                }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} className="pdf-doc" />
              </Document>

              <div className="paginationContainerPdf">
                <p>
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>

                <div className="prevNextContainer">
                  <span
                    onClick={() => (pageNumber > 1 ? onClickPrevious() : null)}
                    className={`${pageNumber <= 1 ? 'prevNextContainer__disabled' : ''}`}
                  >
                    PREVIOUS
                  </span>
                  <span
                    onClick={() => (pageNumber < numPages ? onClickNext() : null)}
                    className={`${
                      pageNumber >= numPages ? 'prevNextContainer__disabled' : ''
                    }`}
                  >
                    NEXT
                  </span>
                </div>
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default UploadPdfButton;
