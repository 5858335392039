import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { states } from '../../../../constants/main';
import { getStringWithoutSymbols } from '../../../../helpers';
import { useAppDispatch } from '../../../../hooks/global';
import { updateFreightAgentInfo } from '../../../../store/freightAgent/freightAgent';
import { fetchUploadFile } from '../../../../store/uploadFile/uploadFile';
import {
  createNewUser,
  deleteUser,
  editUser,
  getUsersList,
} from '../../../../store/user/user';
import Loader from '../../../common/Loader/Loader';
import { Button, Tabs } from '../../../controls';
import './MyProfileFA.scss';
import ContactPersonsFA from './parts_/ContactPersons';
import UserModalFA from './parts_/CreateOrEditUserModal';
import DetailsFA from './parts_/Details';
import GeneralFA from './parts_/General';

interface IProfile {
  selectedProfileTab: any;
  handleChangeProfileTab: any;
  profileTabsList: any;
  detailsOpened: boolean;
  generalOpened: boolean;
  setDetailsOpened: any;
  setGeneralOpened: any;
  companyName: string | null;
  email: string | null;
  generalInfo: any;
}

const MyProfileFA: FC<IProfile> = ({
  selectedProfileTab,
  handleChangeProfileTab,
  profileTabsList,
  detailsOpened,
  generalOpened,
  setDetailsOpened,
  setGeneralOpened,
  companyName,
  email,
  generalInfo,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const ownerId = localStorage.getItem('owner_id');

  const [contactPersonsList, setContactPersonsList] = useState<any>(null);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [addressState, setAddressState] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [newUserData, setNewUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
  });

  const [editUserData, setEditUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    id: '',
  });

  const [w9, setW9] = useState({
    alias: 'w9',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [liabilityInsurance, setLiabilityInsurance] = useState({
    alias: 'liability_insurance',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [operatingPermit, setOperatingPermit] = useState({
    alias: 'operating_permit',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const getContactPersonsList = () => {
    dispatch(
      getUsersList({
        entity_type: 'fa',
        entity_id: ownerId,
      }),
    ).then(res => {
      const users: any = [];
      if (res.type === 'usersList/fulfilled' && res.payload?.resource?.length) {
        res.payload.resource.map((user: any) => {
          users.push({
            first_name: user.profile.first_name,
            last_name: user.profile.last_name,
            phone: user.address.phone,
            email: user.email,
            user_id: user.id,
          });
        });
      }

      setContactPersonsList(users);
    });
  };

  useEffect(() => {
    if (generalInfo.logo) {
      setAvatar({
        ...avatar,
        file: generalInfo.logo,
      });
    }

    if (generalInfo.documents && generalInfo.documents.length) {
      setIsAnyFileUploded(true);
      generalInfo.documents.map((doc: any) => {
        if (doc.alias === 'w9') {
          setW9({
            ...w9,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        } else if (doc.alias === 'liability_insurance') {
          setLiabilityInsurance({
            ...liabilityInsurance,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        } else if (doc.alias === 'operating_permit') {
          setOperatingPermit({
            ...operatingPermit,
            preview: doc.thumbnail,
            fullpath: doc.file,
          });
        }
      });
    }

    if (ownerId) {
      getContactPersonsList();
    }
  }, [generalInfo]);

  const onAddNewUser = () => {
    const userData = {
      user: {
        email: newUserData.email,
      },
      profile: {
        first_name: newUserData.first_name,
        last_name: newUserData.last_name,
      },
      address: {
        phone: newUserData.phone,
      },
    };

    if (
      ownerId &&
      newUserData.first_name?.length &&
      newUserData.last_name?.length &&
      newUserData.email?.length
    ) {
      setSubmitLoading(true);

      dispatch(
        createNewUser({
          entity_type: 'fa',
          entity_id: ownerId,
          userData,
        }),
      ).then(res => {
        setTimeout(() => {
          setSubmitLoading(false);
        }, 1500);

        if (res.type === 'createUser/fulfilled') {
          getContactPersonsList();
        }
        setOpenCreateUserModal(false);
      });
    }
  };

  const onEditUserInfo = () => {
    const userData = {
      user: {
        email: editUserData.email,
      },
      profile: {
        first_name: editUserData.first_name,
        last_name: editUserData.last_name,
      },
      address: {
        phone: editUserData.phone,
      },
      id: editUserData.id,
    };

    if (
      ownerId &&
      editUserData.first_name?.length &&
      editUserData.last_name?.length &&
      editUserData.email?.length
    ) {
      setSubmitLoading(true);

      dispatch(
        editUser({
          entity_type: 'fa',
          entity_id: ownerId,
          userData,
        }),
      ).then(res => {
        setTimeout(() => {
          setSubmitLoading(false);
        }, 1500);

        if (res.type === 'editUser/fulfilled') {
          getContactPersonsList();
          setEditUserData({
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            id: '',
          });

          setEditUserPrevData(null);
        }
        setOpenEditUserModal(false);
      });
    }
  };

  useEffect(() => {
    if (editUserPrevData) {
      setEditUserData(editUserPrevData);
    }
  }, [editUserPrevData]);

  const onDeleteUser = (userId: number) => {
    dispatch(
      deleteUser({
        entity_type: 'fa',
        entity_id: ownerId,
        user_id: userId,
      }),
    ).then(res => {
      if (res.type === 'deleteUser/fulfilled') {
        getContactPersonsList();
      }
    });
  };

  const onChangeContactPerson = (field: string, formType: string) => (ev: any) => {
    if (formType === 'create') {
      setNewUserData({
        ...newUserData,
        [field]: ev.target.value,
      });
    } else if (formType === 'edit') {
      setEditUserData({
        ...editUserData,
        [field]: ev.target.value,
      });
    }
  };

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');

    const docs = [];

    if (w9.file) {
      docs.push(w9);
    }

    if (liabilityInsurance.file) {
      docs.push(liabilityInsurance);
    }

    if (operatingPermit.file) {
      docs.push(operatingPermit);
    }

    if (generalInfo.documents.length) {
      generalInfo.documents.map((doc: any) => {
        if (doc.alias === 'w9' && doc.file && !w9.fullpath) {
          docs.push({
            alias: 'w9',
            delete: 1,
          });
        } else if (
          doc.alias === 'liability_insurance' &&
          doc.file &&
          !liabilityInsurance.fullpath
        ) {
          docs.push({
            alias: 'liability_insurance',
            delete: 1,
          });
        } else if (
          doc.alias === 'operating_permit' &&
          doc.file &&
          !operatingPermit.fullpath
        ) {
          docs.push({
            alias: 'operating_permit',
            delete: 1,
          });
        }
      });
    }

    if (id) {
      setSubmitLoading(true);

      const dataForUpdate: any = {
        id: id,
        title: data.fa_name || generalInfo.companyName,
        info: data.fa_info || generalInfo.carrierInfo,
        mc_authority: data.mc_authority || generalInfo.mcAuthority,
        tax_id: data.tax_id || generalInfo.taxId,
        dot_id: data.dot || generalInfo.dot,
        scac: data.scac_code || generalInfo.scacCode,
        address: {
          address: data.address || generalInfo.address,
          city: data.city || generalInfo.city,
          state: addressState,
          zip: data.zip || generalInfo.zip,
          fax: getStringWithoutSymbols(data.office_fax) || generalInfo.officeFax,
          cell_phone: getStringWithoutSymbols(data.cell_phone) || generalInfo.cellPhone,
        },
        billing_address: {
          address: data.billing_address || generalInfo.billingAddress,
          city: data.billing_city || generalInfo.billingCity,
          state: billingAddressState,
          zip: data.billing_zip || generalInfo.billingZip,
          email: data.payable_email || generalInfo.payableEmail,
          phone: getStringWithoutSymbols(data.payable_phone) || generalInfo.payablePhone,
        },
        user: {
          id: generalInfo.userId,
          email: data.email || generalInfo.email,
        },
      };

      if (docs.length) {
        dataForUpdate.documents = docs;
      }

      if (avatar.temp) {
        dataForUpdate.logo = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(updateFreightAgentInfo(dataForUpdate)).then(res => {
        if (res.type === 'updatingFreightAgentInfo/fulfilled') {
          const userData = localStorage.getItem('user');
          if (userData) {
            const parsedData = JSON.parse(userData);
            localStorage.setItem(
              'user',
              JSON.stringify({ ...parsedData, company_name: res.payload.resource.title }),
            );
          }
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  return (
    <div className="myProfile mainContainer">
      <Loader loading={submitLoading} />

      <div className="myProfile__container">
        <div className="myProfile__body">
          <div className="myProfile__userForm">
            <div className="myProfile__header">
              <div className="myProfile__title">
                <h1>Profile</h1>
                <span>(Freight Agent)</span>
              </div>

              <Tabs
                value={selectedProfileTab}
                onChange={handleChangeProfileTab}
                tabsList={profileTabsList}
              />
            </div>

            <div className="myProfile__details">
              <div className="h2-title">
                <h2 className="h2-title__text">Freight Agent Details</h2>
                <img
                  src={require('../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDetailsOpened(!detailsOpened)}
                  className={`openBtn ${!detailsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <DetailsFA
                detailsOpened={detailsOpened}
                isAnyFileUploded={isAnyFileUploded}
                setIsAnyFileUploded={setIsAnyFileUploded}
                setW9={setW9}
                w9={w9}
                setLiabilityInsurance={setLiabilityInsurance}
                liabilityInsurance={liabilityInsurance}
                setOperatingPermit={setOperatingPermit}
                operatingPermit={operatingPermit}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralFA
                generalOpened={generalOpened}
                control={control}
                generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setBillingAddressState={setBillingAddressState}
              />

              <hr className="horizontalLine fullHr" />
            </div>
          </div>

          <div className="myProfile__contactPersons">
            <div className="h2-title">
              <h2 className="h2-title__text">Contact Persons</h2>

              <img
                src={require('../../../../assets/images/icons/expand-icon.png')}
                alt="expand"
                width="24px"
                height="auto"
                onClick={() => setContactPersonsOpened(!contactPersonsOpened)}
                className={`openBtn ${!contactPersonsOpened ? 'openBtnOpened' : ''}`}
              />
            </div>

            <ContactPersonsFA
              contactPersonsOpened={contactPersonsOpened}
              setOpenCreateUserModal={setOpenCreateUserModal}
              setOpenEditUserModal={setOpenEditUserModal}
              contactPersonsList={contactPersonsList}
              onDeleteUser={onDeleteUser}
              setEditUserPrevData={setEditUserPrevData}
            />

            <hr className="horizontalLine fullHr" />
          </div>

          <Button
            title="SAVE"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            className="saveBtn"
            loading={submitLoading}
          />
        </div>

        <div className="myProfile__userInfo">
          <div className="addNewPhotoBtn">
            <IconButton color="primary" aria-label="upload picture" component="label">
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={e => {
                  if (e && e.target && e.target.files) {
                    const fd = new FormData();
                    fd.append('file', e.target.files[0]);
                    dispatch(fetchUploadFile(fd)).then(res => {
                      if (res.type === 'uploadFile/fulfilled') {
                        setAvatar({
                          file: res.payload.file.fullpath,
                          temp: res.payload.file.filepath,
                        });
                      }
                    });
                  }
                }}
              />
              <CameraAltOutlinedIcon />
            </IconButton>
          </div>
          <div className="userInfo__avatar">
            <img src={avatar.file} alt="" />
          </div>
          <div className="userInfo__name">{generalInfo.companyName}</div>
          <div className="userInfo__role">Freight Agent</div>
          <div className="userInfo__email">{email}</div>
        </div>
      </div>

      {
        <>
          {openCreateUserModal && (
            <UserModalFA
              open={openCreateUserModal}
              setOpen={setOpenCreateUserModal}
              onChange={onChangeContactPerson}
              onSubmit={onAddNewUser}
              confirmTitle="ADD"
              cancelTitle="CANCEL"
              contentTitle="Add New Contact Person"
              formType="create"
              loading={submitLoading}
            />
          )}

          {openEditUserModal && (
            <UserModalFA
              open={openEditUserModal}
              setOpen={setOpenEditUserModal}
              onChange={onChangeContactPerson}
              onSubmit={onEditUserInfo}
              confirmTitle="EDIT"
              cancelTitle="CANCEL"
              contentTitle="Edit Contact Person"
              defaultData={editUserPrevData}
              setDefaultData={setEditUserPrevData}
              formType="edit"
              loading={submitLoading}
            />
          )}
        </>
      }
    </div>
  );
};

export default MyProfileFA;
