import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button, Input } from '../../controls';
import './SetPassword.scss';
import { useAppDispatch } from '../../../hooks/global';
import { fetchResetPassword } from '../../../store/user/user';

const SetPassword: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = (type: string) => {
    if (type === 'confirm') {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  const onSubmit = async (data: any) => {
    const recoveryToken = localStorage.getItem('recovery_token');
    if (recoveryToken && recoveryToken?.length) {
      setLoading(true);

      dispatch(
        fetchResetPassword({
          password: data.password,
          token: recoveryToken,
        }),
      ).then(res => {
        if (res.type === 'resetPassword/fulfilled') {
          localStorage.removeItem('recovery_token');
          navigate('/sign-in');
        } else {
          setLoading(false);
        }
      });
    }
  };

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  return (
    <div className="setPassword l-popup">
      <div className="setPassword__container">
        <img
          src={require('../../../assets/images/icons/reset-password.png')}
          alt="set-password"
          className="setPassword__icon"
        />

        <h1 className="setPassword__title">Set Password</h1>

        <Input
          name="New Password (minimum 8 characters)"
          id="password"
          showPassword={showPassword}
          handleClickShowPassword={handleClickShowPassword}
          type="password"
          className="setPassword__field"
          control={control}
          isError={Boolean(errors.password)}
          required={true}
          minLength={8}
          maxLength={50}
        />
        <Input
          name="Confirm New Password"
          id="confirmPassword"
          watch={watch}
          showPassword={showConfirmPassword}
          handleClickShowPassword={() => handleClickShowPassword('confirm')}
          type="password"
          className="setPassword__field"
          control={control}
          isError={Boolean(errors.confirmPassword)}
          required={true}
          minLength={8}
          maxLength={50}
        />

        <div className="setPassword__btnsContainer">
          <Button
            title="SET NEW PASSWORD"
            className="setPassword__btn"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            id="setNewPassword"
            loading={loading}
          />
        </div>

        <div className="authLinks">
          <span onClick={goToSignIn}>Sign-In Here</span>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
