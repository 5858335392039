import React, { FC } from 'react';
import {
  ContainedInput,
  RadioButton,
  Selection,
  UploadPdfButton,
} from '../../../../../controls';
import GoogleAutocompleteComponent from '../../../../../controls/GoogleAutocomplete/Autocomplete';

interface IGeneral {
  generalOpened: boolean;
  control: any;
  generalInfo: any;
  states: any;
  setAddressState: any;
  setMailingAddressState: any;
  setIsHazmat: any;
  isHazmat: any;
  setAddressComponents: any;
  zip?: any;
  state?: any;
  city?: any;
  setAddressMailingComponents: any;
  zipMailing: any;
  stateMailing: any;
  cityMailing: any;
  mailingAddress: any;
  address: any;
}

const GeneralTC: FC<IGeneral> = ({
  generalOpened,
  control,
  generalInfo,
  states,
  setAddressState,
  setMailingAddressState,
  setIsHazmat,
  isHazmat,
  setAddressComponents,
  zip,
  state,
  city,
  setAddressMailingComponents,
  zipMailing,
  stateMailing,
  cityMailing,
  mailingAddress,
  address,
}) => {
  return (
    <>
      {generalOpened && generalInfo && generalInfo?.id && (
        <>
          <div>
            <div className="general__line-1">
              <ContainedInput
                label="FL#"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.entity.fl_number}
                control={control}
                readOnly={true}
              />
              <ContainedInput
                label="Carrier Information"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.info}
                control={control}
                id="carrier_info"
              />
              <ContainedInput
                label="Company Carrier Name"
                defaultValue={generalInfo.title}
                control={control}
                id="carrier_name"
                className="inputFlexOne"
              />
            </div>
            <div className="general__line-1">
              {/* <ContainedInput
                label="Address"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.address.address}
                control={control}
                id="address"
              /> */}
              <GoogleAutocompleteComponent label="Address" setAddressComponents={setAddressComponents} defaultValue={address}/>
              <ContainedInput
                label="City"
                className="marginRight inputFlexOne"
                defaultValue={city}
                control={control}
                id="city"
              />
              <div className="inputsContainer inputFlexOne">
                <Selection
                  label="State"
                  className="marginRight customWidthState inputFlexOne"
                  items={states}
                  setValue={setAddressState}
                  defaultValue={state}
                />
                <ContainedInput
                  label="ZIP Code"
                  className="customWidthZip inputFlexOne"
                  defaultValue={zip}
                  control={control}
                  id="zip"
                />
              </div>
            </div>
            <div className="general__line-1">
              {/* <ContainedInput
                label="Mailing Address"
                className="marginRight inputFlexOne"
                defaultValue={generalInfo.mailing_address.address}
                control={control}
                id="mailing_address"
              /> */}
              <GoogleAutocompleteComponent label="Mailing Address" setAddressComponents={setAddressMailingComponents} defaultValue={mailingAddress}/>
              <ContainedInput
                label="City"
                className="marginRight inputFlexOne"
                defaultValue={cityMailing}
                control={control}
                id="mailing_city"
              />
              <div className="inputsContainer inputFlexOne">
                <Selection
                  label="State"
                  className="marginRight customWidthState inputFlexOne"
                  items={states}
                  name="mailing"
                  setValue={setMailingAddressState}
                  defaultValue={stateMailing}
                />
                <ContainedInput
                  label="ZIP Code"
                  className="customWidthZip inputFlexOne"
                  defaultValue={zipMailing}
                  control={control}
                  id="mailing_zip"
                />
              </div>
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="MC Authority #"
                className="marginRight"
                defaultValue={generalInfo.mc_authority}
                control={control}
                id="mc_authority"
              />
              <ContainedInput
                label="Tax ID #"
                className="marginRight"
                defaultValue={generalInfo.tax_id}
                control={control}
                id="tax_id"
              />
              <ContainedInput
                label="DOT #"
                defaultValue={generalInfo.dot_id}
                control={control}
                id="dot"
              />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="SCAC Code"
                className="marginRight"
                defaultValue={generalInfo.scac}
                control={control}
                id="scac_code"
              />
              <RadioButton
                label="Hazmat Certified?"
                className="marginRight"
                firstVariant="Yes"
                secondVariant="No"
                onHandleChangeValue={setIsHazmat}
                defaultValue={isHazmat}
              />
              <ContainedInput
                label="Email"
                control={control}
                id="email"
                defaultValue={generalInfo.user.email}
              />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Office Phone"
                className="marginRight"
                defaultValue={generalInfo.address.phone}
                control={control}
                id="office_phone"
                type="phone"
              />
              <ContainedInput
                label="Office Fax"
                className="marginRight"
                defaultValue={generalInfo.address.fax}
                control={control}
                id="office_fax"
                type="phone"
              />
              <ContainedInput
                label="Cell Phone"
                defaultValue={generalInfo.address.cell_phone}
                control={control}
                id="cell_phone"
                type="phone"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GeneralTC;
