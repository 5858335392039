import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { TemporaryDrawer } from "./parts/Drawer/Drawer";
import "./Header.scss";

type Anchor = "top" | "left" | "bottom" | "right";

interface IHeader {
  avatar: any;
  email: any;
  blockAccess: any;
}

const Header: FC<IHeader> = ({ avatar, email, blockAccess }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedLink, setSelectedLink] = useState<string>("Profiles");
  const [drawOpened, setDrawOpened] = useState<object>({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawOpened({ [anchor]: open });
    };

  const goToUserManagement = () => {
    setSelectedLink("Users Management");
    navigate("/users-management");
  };

  const goToInvoicing = () => {
    setSelectedLink("Invoicing");
    navigate("/invoicing");
  };

  const goToLoads = () => {
    setSelectedLink("Loads");
    navigate("/loads");
  };

  return (
    <div className="header">
      <div className="header__logo">
        <img
          src={require("../../../assets/images/main-logo.png")}
          alt="Logo"
          className="header__logoImage"
        />
      </div>

      <div className="header__navLinks">
        {!blockAccess && (
          <div
            className={`header__navLink ${
              location.pathname === "/users-management" ||
              location.pathname === "/create-driver" ||
              location.pathname === "/edit-driver"
                ? "navLink_selected"
                : ""
            }`}
            onClick={() => goToUserManagement()}
          >
            Admin Users Management
          </div>
        )}
        <div
          className={`header__navLink ${
            location.pathname === "/loads" || location.pathname === "/loads/map"
              ? "navLink_selected"
              : ""
          }`}
          onClick={() => goToLoads()}
        >
          Loads Data Access
        </div>
        <div
          className={`header__navLink ${
            location.pathname === "/payment-reports" ? "navLink_selected" : ""
          }`}
          onClick={() => null}
        >
          Payments Reports and Settings
        </div>
        <div
          className={`header__navLink ${
            location.pathname === "/invoicing" ? "navLink_selected" : ""
          }`}
          onClick={() => goToInvoicing()}
        >
          Invoicing
        </div>
      </div>

      <div className="header__userWindow">
        <div className="userWindow_icon">
          <img src={avatar} alt="" />
        </div>

        <div className="userWindow_data">
          <span className="secondary_text">
            Welcome <span className="userWindow_name">{email}</span>
          </span>
          {/* <span className="secondary_text">
            FL#: <span className="userWindow_name">123456</span>
          </span> */}
        </div>

        <React.Fragment key={"drawer"}>
          <div
            className="userWindow_arrow"
            onClick={toggleDrawer("right", true)}
          ></div>
          <TemporaryDrawer
            drawOpened={drawOpened}
            toggleDrawer={toggleDrawer}
          />
        </React.Fragment>
      </div>
    </div>
  );
};

export default Header;
