import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api, { apiWithBaseUrl } from '../../service/api';

export const fetchUploadFile = createAsyncThunk('uploadFile', async (file: any) => {
  const response = await api.post(`file/upload`, file);
  return response.data;
});

export const fetchUploadPrivateFile = createAsyncThunk(
  'uploadPrivateFile',
  async (privateFile: any) => {
    const response = await apiWithBaseUrl(privateFile.privateFile).get('');
    return response.data;
  },
);

interface TruckCompanyState {
  carrierInfo: string | null;
  companyName: string | null;
}

const initialState: TruckCompanyState = {
  carrierInfo: null,
  companyName: null,
};

export const uploadFile = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    //Get truck company info
    builder.addCase(fetchUploadPrivateFile.fulfilled, (state, action) => {});
  },
});

export default uploadFile.reducer;
