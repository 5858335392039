import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../service/api";
import { UpdateTruckCompanyModel } from "../../models/truckCompany/truckCompany.model";

export const getTruckCompanyInfo = createAsyncThunk(
  "truckCompanyInfo",
  async (id: number) => {
    const response = await api.get(`tc/${id}/get`);
    return response.data;
  }
);

export const updateTruckCompanyInfo = createAsyncThunk(
  "updatingTruckCompanyInfo",
  async (truckCompany: UpdateTruckCompanyModel) => {
    const response = await api.post(
      `tc/${truckCompany.id}/update`,
      truckCompany
    );
    return response.data;
  }
);

export const deleteTruckCompany = createAsyncThunk(
  "deleteTruckCompany",
  async (truckCompany: { id: number }) => {
    const response = await api.post(`tc/${truckCompany.id}/delete`);
    return response.data;
  }
);

export const createTruckCompany = createAsyncThunk(
  "createTruckCompany",
  async (truckCompany: UpdateTruckCompanyModel) => {
    const response = await api.post(`tc/create`, truckCompany);
    return response.data;
  }
);

export const getTruckCompanyList = createAsyncThunk(
  "getTruckCompanyList",
  async (data: any) => {
    let requestString = `tc/list?page=${data.page}`;

    if (data.companyName) {
      requestString += `&company=${data.companyName}`;
    }

    if (data.city) {
      requestString += `&city=${data.city}`;
    }

    if (data.state) {
      requestString += `&state=${data.state}`;
    }
    
    const response = await api.get(requestString);
    return response.data;
  }
);

interface TruckCompanyState {
  carrierInfo: string | null;
  companyName: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  mailingAddress: string | null;
  mailingCity: string | null;
  mailingState: string | null;
  mailingZip: string | null;
  mcAuthority: string | null;
  taxId: string | null;
  dot: string | null;
  scacCode: string | null;
  isHazmat: string | null;
  officePhone: string | null;
  officeFax: string | null;
  cellPhone: string | null;
  errorMessage: string | null;
  logo: any;
  documents: [] | null;
  entityId: number | null;
  flNumber: string | null;
  email: any;
  userId: any;
}

const initialState: TruckCompanyState = {
  carrierInfo: null,
  companyName: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  mailingAddress: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  mcAuthority: null,
  taxId: null,
  dot: null,
  scacCode: null,
  isHazmat: null,
  officePhone: null,
  officeFax: null,
  cellPhone: null,
  errorMessage: null,
  logo: null,
  documents: [],
  entityId: null,
  flNumber: null,
  email: null,
  userId: null,
};

export const truckCompany = createSlice({
  name: "user",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //Get truck company info
    builder.addCase(getTruckCompanyInfo.fulfilled, (state, action) => {
      state.carrierInfo = action.payload.resource.info || null;
      state.companyName = action.payload.resource.title || null;
      state.address = action.payload.resource.address.city || null;
      state.city = action.payload.resource.address.city || null;
      state.state = action.payload.resource.address.state?.code || null;
      state.zip = action.payload.resource.address.zip || null;
      state.mailingAddress =
        action.payload.resource.mailing_address.address || null;
      state.mailingCity = action.payload.resource.mailing_address.city || null;
      state.mailingState =
        action.payload.resource.mailing_address.state?.code || null;
      state.mailingZip = action.payload.resource.mailing_address.zip || null;
      state.mcAuthority = action.payload.resource.mc_authority || null;
      state.taxId = action.payload.resource.tax_id || null;
      state.dot = action.payload.resource.dot_id || null;
      state.scacCode = action.payload.resource.scac || null;
      state.isHazmat = action.payload.resource.is_hazmat || null;
      state.officePhone = action.payload.resource.address.phone || null;
      state.officeFax = action.payload.resource.address.fax || null;
      state.cellPhone = action.payload.resource.address.cell_phone || null;
      state.logo = action.payload.resource.logo?.file || null;
      state.documents = action.payload.resource.documents || null;
      state.entityId = action.payload.resource.entity.id || null;
      state.flNumber = action.payload.resource.entity.fl_number || null;
      state.email = action.payload.resource.user.email || null;
      state.userId = action.payload.resource.user.id || null;
    });
    builder.addCase(getTruckCompanyInfo.rejected, (state, action) => {
      state.errorMessage = action.error.message || "";
    });

    builder.addCase(updateTruckCompanyInfo.fulfilled, (state, action) => {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  },
});

export default truckCompany.reducer;
