import React, { FC } from 'react';
import {
  ContainedInput,
  RadioButton,
  Selection,
  UploadPdfButton,
} from '../../../../../controls';

interface IGeneral {
  generalOpened: boolean;
  control: any;
  generalInfo?: any;
  states: any;
  setAddressState: any;
  setMailingAddressState: any;
  setIsHazmat: any;
  isHazmat: any;
}

const GeneralID: FC<IGeneral> = ({
  generalOpened,
  control,
  generalInfo,
  states,
  setAddressState,
  setMailingAddressState,
  setIsHazmat,
  isHazmat,
}) => {
  return (
    <>
      {generalOpened && (
        <>
          <div>
            <div className="general__line-1">
              <ContainedInput
                label="FL#"
                className="marginRight"
                // defaultValue={generalInfo.flNumber}
                control={control}
                readOnly={true}
              />
              <ContainedInput
                label="Carrier Information"
                className="marginRight"
                // defaultValue={generalInfo.carrierInfo}
                control={control}
                id="carrier_info"
              />
              <ContainedInput
                label="Company Carrier Name*"
                // defaultValue={generalInfo.companyName}
                control={control}
                id="carrier_name"
              />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="First Name"
                className="marginRight"
                // defaultValue={generalInfo.flNumber}
                control={control}
                id="first_name"
              />
              <ContainedInput
                label="Last Name"
                className="marginRight"
                // defaultValue={generalInfo.carrierInfo}
                control={control}
                id="last_name"
              />
              <ContainedInput label="" control={control} className="hiddenBlock" />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Address"
                className="marginRight inputFlexOne"
                // defaultValue={generalInfo.address}
                control={control}
                id="address"
              />
              <ContainedInput
                label="City"
                className="marginRight inputFlexOne"
                // defaultValue={generalInfo.city}
                control={control}
                id="city"
              />
              <div className="inputsContainer inputFlexOne">
                <Selection
                  label="State"
                  className="marginRight customWidthState inputFlexOne"
                  items={states}
                  setValue={setAddressState}
                  // defaultValue={generalInfo.state}
                />
                <ContainedInput
                  label="ZIP Code"
                  className="customWidthZip inputFlexOne"
                  // defaultValue={generalInfo.zip}
                  control={control}
                  id="zip"
                />
              </div>
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Mailing Address"
                className="marginRight inputFlexOne"
                // defaultValue={generalInfo.mailingAddress}
                control={control}
                id="mailing_address"
              />
              <ContainedInput
                label="City"
                className="marginRight inputFlexOne"
                // defaultValue={generalInfo.mailingCity}
                control={control}
                id="mailing_city"
              />
              <div className="inputsContainer inputFlexOne">
                <Selection
                  label="State"
                  className="marginRight customWidthState inputFlexOne"
                  items={states}
                  name="mailing"
                  setValue={setMailingAddressState}
                  // defaultValue={generalInfo.mailingState}
                />
                <ContainedInput
                  label="ZIP Code"
                  className="customWidthZip inputFlexOne"
                  // defaultValue={generalInfo.mailingZip}
                  control={control}
                  id="mailing_zip"
                />
              </div>
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="MC Authority #"
                className="marginRight"
                // defaultValue={generalInfo.mcAuthority}
                control={control}
                id="mc_authority"
              />
              <ContainedInput
                label="Tax ID #"
                className="marginRight"
                // defaultValue={generalInfo.taxId}
                control={control}
                id="tax_id"
              />
              <ContainedInput
                label="DOT #"
                // defaultValue={generalInfo.dot}
                control={control}
                id="dot"
              />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="SCAC Code"
                className="marginRight"
                //   defaultValue={generalInfo.scacCode}
                control={control}
                id="scac_code"
              />
              <RadioButton
                label="Hazmat Certified?"
                className="marginRight"
                firstVariant="Yes"
                secondVariant="No"
                onHandleChangeValue={setIsHazmat}
                defaultValue={isHazmat}
              />
              <ContainedInput label="Email*" control={control} id="email" />
            </div>
            <div className="general__line-1">
              <ContainedInput
                label="Office Phone"
                className="marginRight"
                // defaultValue={generalInfo.officePhone}
                control={control}
                id="office_phone"
                type="phone"
              />
              <ContainedInput
                label="Office Fax"
                className="marginRight"
                // defaultValue={generalInfo.officeFax}
                control={control}
                id="office_fax"
                type="phone"
              />
              <ContainedInput
                label="Cell Phone"
                // defaultValue={generalInfo.cellPhone}
                control={control}
                id="cell_phone"
                type="phone"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GeneralID;
