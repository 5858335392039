import React, { FC, useEffect, useState } from 'react';
import { ContainedInput, ContainedInput_, Modal } from '../../../../controls';
import InputMask from 'react-input-mask';
import { getStringWithoutSymbols } from '../../../../../helpers';
import { CreateCustomer } from '../../..';
import './CreateCustomerModal.scss';
import { useAppDispatch } from '../../../../../hooks/global';
import { createTruck } from '../../../../../store/load/load';

interface IUserModal {
  open: any;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  loading?: boolean;
  setUsersList?: any;
  usersList?: any;
  customerType?: string;
  setLocation?: any;
  setGuestDriver?: any;
}

const CreateGuestDriverModal: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  loading,
  setUsersList,
  usersList,
  customerType,
  setLocation,
  setGuestDriver,
}) => {
  const dispatch = useAppDispatch();
  const id = localStorage.getItem('owner_id');
  const userData = localStorage.getItem('user');
  let parsedData: any = null;

  if (userData) {
    parsedData = JSON.parse(userData);
  }

  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
  const [clickOnSubmit, setClickOnSubmit] = useState<boolean>(false);
  const [newCustomer, setNewCustomer] = useState<any>(null);

  const [truckNumber, setTruckNumber] = useState<any>(null);
  const [truckId, setTruckId] = useState<any>(null);

  const [driverName, setDriverName] = useState<any>(null);
  const [driverEmail, setDriverEmail] = useState<any>(null);

  const onSubmit_ = () => {
    setGuestDriver({
      name: driverName,
      email: driverEmail,
    })

    setOpen(false);
  };

  const onChange_ = (e: any, type: string) => {
    if (type === 'email') {
      setDriverEmail(e.target.value)
    }

    if (type === 'name') {
      setDriverName(e.target.value)
    }
  };

  return (
    <Modal
      open={open}
      disabled={btnDisabled}
      // onConfirm={() => setClickOnSubmit(true)}
      onConfirm={onSubmit_}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="customer-modal"
      content={
        <>
          <div className="guestDriverLabel">Create Guest Driver</div>
          <div
            className={`customizedInputContainer inputFlexOne`}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="label">Full Name</div>
            <input
              className="customizedInputContainer__input"
              defaultValue={truckNumber}
              onChange={(e: any) => onChange_(e, 'name')}
              style={{ width: '50%', marginBottom: '10px' }}
            />
          </div>

          <div
            className={`customizedInputContainer inputFlexOne`}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="label">Email</div>
            <input
              className="customizedInputContainer__input"
              defaultValue={truckNumber}
              onChange={(e: any) => onChange_(e, 'email')}
              style={{ width: '50%', marginBottom: '10px' }}
            />
          </div>
        </>
      }
    />
  );
};

export default CreateGuestDriverModal;
