import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { displayProtectedImage, displayImageSrc } from '../../../helpers';
import { useAppDispatch } from '../../../hooks/global';
import { getTicketIncident } from '../../../store/reports/reports';
import { ITicketIncidentData } from './interfaces/interface';
import { Button } from '../../controls';
import { ModalPicture } from '../../controls';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import './TicketIncident.scss';

interface ITicketIncident {
  partialBlockAccess?: boolean;
}

const TicketIncident: FC<ITicketIncident> = ({ partialBlockAccess }) => {
  const [openTicket, setOpenTicket] = useState<boolean>(false);
  const [loadInfo, setLoadInfo] = useState<ITicketIncidentData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [open, setOpen] = useState(false);
  const [arrayImages, setArrayImages] = useState<string[]>([]);

  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const goToReportsList = () => navigate(-1);

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getTicketIncident({
          id: params.id,
        }),
      ).then((res: any) => {
        if (res.type === 'getTicketIncident/fulfilled') {
          if (res.payload.resource) {
            setLoadInfo(res.payload.resource);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loadInfo) setLoading(false);
    if (loadInfo?.pictures && loadInfo?.pictures?.length > 0) {
      loadInfo.pictures.forEach((picture: any) => {
        displayProtectedImage(
          picture?.id,
          picture.thumbnail ? picture.thumbnail : '',
          token,
        );
        setArrayImages(
          arrayImages => (arrayImages = [...arrayImages, picture?.thumbnail]),
        );
      });
    }
  }, [loadInfo]);

  const handleOpen = (picture: string) => {
    setOpenTicket(true);
    displayImageSrc('modal', picture, token);
  };

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  return (
    <>
      <div className="reportIncident mainContainer">
        <div className="reportIncident__container">
          <div className="reportIncident__body">
            <div className="reportIncident__header">
              <div className="reportIncident__title">
                <h1>Report</h1>
              </div>

              <div className="reportIncident__btnWrapper">
                <Button
                  onClick={() => goToReportsList()}
                  Icon={<KeyboardBackspaceTwoToneIcon />}
                  className="backBtn"
                  variant="outlined"
                  title={'back'}
                />
              </div>
            </div>
            <div className="componentHeader">
              <h2 className="componentHeader__title">
                Ticket/Dot Violation Report (
                {loadInfo?.report?.load_number ? loadInfo?.report?.load_number : ' - '})
              </h2>
            </div>
            <div className="reportIncident__main">
              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">
                    Pictures of Ticket/Dot Violation
                  </h2>
                </div>

                <div className={`accContainer__contentWrapper -opened`}>
                  <div className="accContainer__content">
                    <ul className="accPictureList">
                      {loadInfo?.pictures && loadInfo?.pictures?.length > 0 ? (
                        loadInfo.pictures.map((picture: any) => {
                          return (
                            <li key={picture?.id} className="accPictureList__item">
                              <div
                                className="accPictureList__itemBtn"
                                onClick={() =>
                                  handleOpen(picture.file ? picture.file : '')
                                }
                                id={picture?.id}
                              ></div>
                            </li>
                          );
                        })
                      ) : (
                        <>
                          <li className="accPictureList__item"></li>
                          <li className="accPictureList__item"></li>
                          <li className="accPictureList__item"></li>
                          <li className="accPictureList__item"></li>
                          <li className="accPictureList__item"></li>
                          <li className="accPictureList__item"></li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accContainer">
                <div className="accContainer__header">
                  <h2 className="accContainer__titleText">Descripton of Incident</h2>
                </div>

                <div className={`accContainer__contentWrapper -opened`}>
                  <div className="accContainer__content">
                    <div className="incidentDescription">
                      <div className="incidentDescription__row">
                        {loadInfo?.desc ? loadInfo?.desc : ' - '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPicture open={openTicket} onClose={handleCloseTicket} />
    </>
  );
};

export default TicketIncident;
