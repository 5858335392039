import React, { useEffect } from 'react';
import { Checkbox } from '@mui/material';
import './Checkbox.scss';

interface ICheckboxProps {
  defaultChecked?: boolean;
  setCheckedValue?: any;
}

export const CheckboxComponent: React.FC<ICheckboxProps> = ({ setCheckedValue, defaultChecked }) => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setCheckedValue(checked);
  }, [checked]);

  useEffect(() => {
    if (defaultChecked === true) {
      setChecked(true);
    }
    if (defaultChecked === false) {
      setChecked(false);
    }
  }, [defaultChecked])

  return (
    <div className="checkboxCustom">
      <Checkbox
        defaultChecked
        color="primary"
        checked={checked}
        onChange={handleChange}
      />
    </div>
  );
};
