import { MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './Autocomplete.scss';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { ContainedInput, ContainedInput_ } from '..';

interface IUploadButton {
  label: string;
  className?: string;
  name?: string;
  setModalOpen?: any;
  setSearchValue?: any;
  //   setValue?: any;
    defaultValue?: any;
  setAddressComponents?: any;
}

const API_KEY = 'AIzaSyBCmsQaEPNapVOBwx2xdP7vhFcH34NHGxw';

const GoogleAutocompleteComponent: FC<IUploadButton> = ({
  label,
  className,
  setAddressComponents,
  defaultValue,
}) => {
  const [search, setSearch] = useState<any>('');
  const [value, setValue] = useState<any>('');

  const handleInputChange = (e: any) => {
    setSearch(e.target.value);
    setValue(e.target.value);
  };

  const handleSelectSuggest = (suggest: any) => {
    let streetNumber = '';
    let route = '';

    const addressComponents: any = {
      address_components: suggest.address_components,
      lat: suggest.geometry.location.lat(),
      lon: suggest.geometry.location.lng(),
      address: suggest.formatted_address
    };

    setSearch('');

    suggest.address_components.map((component: any) => {
      component.types.map((type: any) => {
        if (type === 'street_number') {
          streetNumber = component.long_name
        } else if (type === 'route') {
          route = component.long_name
        }
      });
    });

    addressComponents.shortAddress = `${streetNumber} ${route}`;
    setAddressComponents(addressComponents)
    setValue(`${streetNumber} ${route}`);
  };

  return (
    <div className={`customizedInputContainer ${className ? className : ''} marginRight inputFlexOne`}>
      <div className="label">{label}</div>

      <ReactGoogleMapLoader
        params={{
          key: API_KEY,
          libraries: 'places,geocode',
        }}
        render={googleMaps =>
          googleMaps && (
            <div>
              <ReactGooglePlacesSuggest
                autocompletionRequest={{ input: search }}
                googleMaps={googleMaps}
                onSelectSuggest={handleSelectSuggest}
              >
                <input
                  type="text"
                  value={value ? value : defaultValue}
                  defaultValue={defaultValue}
                  placeholder="Search a location"
                  onChange={handleInputChange}
                  className="customizedInputContainer__input"
                />
              </ReactGooglePlacesSuggest>
            </div>
          )
        }
      />
    </div>
  );
};

export default GoogleAutocompleteComponent;
