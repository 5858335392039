import { Stack } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Button, Input } from "../../controls";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import GoogleIcon from "@mui/icons-material/Google";
import "./SignIn.scss";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { fetchSignIn } from "../../../store/user/user";
import { useAppDispatch } from "../../../hooks/global";

const SignIn: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setLoading(true);

    dispatch(
      fetchSignIn({
        username: data.userEmail.trim(),
        password: data.password.trim(),
      })
    ).then((res) => {
      if (res.type === "signin/fulfilled") {
        setTimeout(() => {
          navigate("/users-management");
        }, 1500);
      } else {
        setLoading(false);
      }
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const goToSignUp = () => {
    navigate("/sign-up");
  };

  const goToResetPassword = () => {
    navigate("/recovery-password");
  };

  return (
    <div className="signInContainer l-popup">
      <img
        src={require("../../../assets/images/main-logo.png")}
        alt="Logo"
        className="mainLogo"
      />

      <div className="signInView">
        <h1 className="signInTitle">Administration Log In</h1>

        <div className="signInForm">
          <Stack spacing={1}>
            <Input
              id="userEmail"
              name="Email"
              type="text"
              className="textField"
              control={control}
              isError={Boolean(errors.userEmail)}
              required={true}
              minLength={1}
              maxLength={50}
              withoutSpaces={true}
              fromSignIn={true}
            />
            <Input
              name="Password"
              id="password"
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              type="password"
              className="textField"
              control={control}
              isError={Boolean(errors.password)}
              required={true}
              maxLength={50}
              formType="login"
              withoutSpaces={true}
              fromSignIn={true}
            />
          </Stack>

          <div className="horizontalLine">
            <hr />
            <span>OR</span>
            <hr />
          </div>

          <div className="registrationBtns">
            {/* <Button
              title="Sign-In with Facebook"
              Icon={<FacebookRoundedIcon color="warning" />}
              onClick={() => null}
              className="authBtn authBtn__fb"
              variant="outlined"
            />
            <Button
              title="Sign-In with Google"
              Icon={<GoogleIcon color="primary" />}
              onClick={() => null}
              className="authBtn"
              variant="outlined"
            /> */}
            <Button
              title="LOGIN"
              onClick={handleSubmit(onSubmit)}
              className="authBtn authBtn__login"
              variant="contained"
              type="submit"
              id="login"
              loading={loading}
            />

            <div className="authLinks">
              <span onClick={goToResetPassword}>Forgot password?</span>
              {/* <span onClick={goToSignUp}>Sign Up</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
