import React, { FC, useEffect, useState } from 'react';
import { Modal, RadioButton } from '../../../../controls';
import InputMask from 'react-input-mask';

interface IUserModal {
  open: boolean;
  setOpen: any;
  onChange: any;
  onSubmit: any;
  confirmTitle: string;
  cancelTitle: string;
  contentTitle: string;
  defaultData?: any;
  setDefaultData?: any;
  formType?: string;
  setUserRole?: any;
  loading?: boolean;
}

const UserModalID: FC<IUserModal> = ({
  open,
  setOpen,
  onChange,
  onSubmit,
  confirmTitle,
  cancelTitle,
  contentTitle,
  defaultData,
  setDefaultData,
  formType,
  setUserRole,
  loading,
}) => {
  const [emailError, setEmailError] = useState<any>(null);
  const [phoneError, setPhoneError] = useState<any>(null);
  const [firstNameError, setFirstNameError] = useState<any>(null);
  const [lastNameError, setLastNameError] = useState<any>(null);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');

  const [roleValue, setRoleValue] = useState(0);

  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  const phoneRegex = new RegExp(
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
  );
  const textRegex = new RegExp(/^[a-zA-Z]*$/);

  useEffect(() => {
    if (!open && setDefaultData) {
      setDefaultData(null);
    }
  }, [open]);

  useEffect(() => {
    if (defaultData) {
      setEmailValue(defaultData.email);
      setPhoneValue(defaultData.phone);
      setFirstNameValue(defaultData.first_name);
      setLastNameValue(defaultData.last_name);
      setRoleValue(defaultData.userRole);
    }
  }, [defaultData]);

  const onHandleSubmit = () => {
    let firstNameError_ = false;
    let lastNameError_ = false;
    let emailError_ = false;
    let phoneError_ = false;

    if (!textRegex.test(firstNameValue) || !firstNameValue?.length) {
      setFirstNameError('First Name is invalid!');
      firstNameError_ = true;
    }

    if (!textRegex.test(lastNameValue) || !lastNameValue?.length) {
      setLastNameError('Last Name is invalid!');
      lastNameError_ = true;
    }

    if (!emailRegex.test(emailValue)) {
      setEmailError('Email is invalid!');
      emailError_ = true;
    }

    if (!phoneRegex.test(phoneValue) && phoneValue?.length) {
      setPhoneError('Phone is invalid!');
      phoneError_ = true;
    }

    if (!firstNameError_ && !lastNameError_ && !emailError_ && !phoneError_) {
      onSubmit();
    } else {
      return null;
    }
  };

  useEffect(() => {
    setUserRole(roleValue);
  }, [roleValue]);

  const onHandleChange = (ev: any, field: string) => {
    onChange(field, formType)(ev);

    if (field === 'email') {
      setEmailError(null);
      setEmailValue(ev.target.value);
    }

    if (field === 'phone') {
      setPhoneError(null);
      setPhoneValue(ev.target.value);
    }

    if (field === 'first_name') {
      setFirstNameError(null);
      setFirstNameValue(ev.target.value);
    }

    if (field === 'last_name') {
      setLastNameError(null);
      setLastNameValue(ev.target.value);
    }
  };

  useEffect(() => {
    if (emailError || firstNameError || lastNameError || phoneError) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [emailError, firstNameError, lastNameError, phoneError]);

  return (
    <Modal
      open={open}
      disabled={btnDisabled}
      onConfirm={onHandleSubmit}
      loading={loading}
      confirmTitle={confirmTitle}
      cancelTitle={cancelTitle}
      onCancel={() => {
        setOpen(false);
      }}
      modalName="create-user-modal"
      content={
        <div className="general__line-1">
          <h2 className="contactPersonTitle">{contentTitle}</h2>

          <div className="fieldsInModal">
            <div className="fieldsInModal__field">
              <span className="fieldsInModal__label">First Name</span>
              <input
                className={`${firstNameError ? 'errorInput' : ''} marginRight`}
                onChange={ev => onHandleChange(ev, 'first_name')}
                defaultValue={defaultData ? defaultData.first_name : ''}
              />
              {firstNameError && <span className="errorText">{firstNameError}</span>}
            </div>
            <div className="fieldsInModal__field">
              <span className="fieldsInModal__label">Last Name</span>
              <input
                className={`${lastNameError ? 'errorInput' : ''} marginRight`}
                onChange={ev => onHandleChange(ev, 'last_name')}
                defaultValue={defaultData ? defaultData.last_name : ''}
              />
              {lastNameError && <span className="errorText">{lastNameError}</span>}
            </div>
            <div className="fieldsInModal__field">
              <span className="fieldsInModal__label">Email</span>
              <input
                className={`${emailError ? 'errorInput' : ''} marginRight`}
                onChange={ev => onHandleChange(ev, 'email')}
                defaultValue={defaultData ? defaultData.email : emailValue}
              />
              {emailError && <span className="errorText">{emailError}</span>}
            </div>
            <div className="fieldsInModal__field">
              <span className="fieldsInModal__label">Phone</span>
              <InputMask
                className={`${phoneError ? 'errorInput' : ''}`}
                onChange={ev => onHandleChange(ev, 'phone')}
                defaultValue={defaultData ? defaultData.phone : phoneValue}
                mask="+1\ (999) 999-9999"
              />
              {phoneError && <span className="errorText">{phoneError}</span>}
            </div>

            <div className="fieldsInModal__field fieldRadio">
              <RadioButton
                label="User Role"
                firstVariant="Admin"
                secondVariant="Regular User"
                className="marginRight"
                onHandleChangeValue={setRoleValue}
                defaultValue={defaultData ? defaultData.userRole : roleValue}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default UserModalID;
