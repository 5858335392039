import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../service/api';
import { IReportsList } from '../../components/pages/ReportsList/interfaces/interface';


export const getReportsList = createAsyncThunk('getReportsList', async(data : any) => {
  
  const reportType = data.reportType && data.reportType !== null ? `&type=${data.reportType}` : "";
  const startDate = data.dateFrom && data.dateFrom !== null && data.dateFrom !== null ? `&start_date=${data.dateFrom}` : "";
  const endDate = data.dateTo && data.dateTo !== null && data.dateTo !== null ? `&end_date=${data.dateTo}` : "";

  const response = await api.get(`reports/list?page=${data.page}&ipp=20&order_by=${data.sort}&order_dir=${data.sortDirection}${reportType}${startDate}${endDate}`);
  return response;
});

export const getReportAccident = createAsyncThunk('getReportAccident', async (data: any) => {
  const response = await api.get(`reports/accident-report/${data.id}`);
  return response.data;
});

export const getReportDamage = createAsyncThunk('getReportDamage', async (data: any) => {
  const response = await api.get(`reports/damage-report/${data.id}`);
  return response.data;
});

export const getTicketIncident = createAsyncThunk('getTicketIncident', async (data: any) => {
  const response = await api.get(`reports/violation-report/${data.id}`);
  return response.data;
});

interface IInitialState{
  loading: boolean,
  reportsListState: IReportsList | null,
}

const initialState: IInitialState = {
  reportsListState: null,
  loading: false,
};

export const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getReportsList.pending, state => {
        state.loading = true;
      })
      .addCase(getReportsList.fulfilled, (state, action) => {
        state.reportsListState = action.payload;
        state.loading = false;
      })
      .addDefaultCase(() => {});
  },
});

export default reports.reducer;
