import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../service/api";


export const getSpecificLoad = createAsyncThunk("getSpecificLoad", async(id: any) => {
  const response = api.get(`loads/get/${id}`)
  return response;
})

const initialState: any = {
  load: null,
  loading: false,
};

export const specificLoad = createSlice({
  name: "specificLoad",
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getSpecificLoad.pending, state => {
        state.loading = true;
      })
      .addCase(getSpecificLoad.fulfilled, (state, action)=>{
        state.loading = false;
        state.load = action.payload
      })
      .addDefaultCase(()=> {})
  }
})

export default specificLoad.reducer;