import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IconButton } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { useAppDispatch } from '../../../../../hooks/global';
import { createDriver } from '../../../../../store/driver/driver';
import { updateTruckCompanyInfo } from '../../../../../store/truckCompany/truckCompany';
import { fetchUploadFile } from '../../../../../store/uploadFile/uploadFile';
import {
  createNewUser,
  deleteUser,
  editUser,
  getUsersList,
} from '../../../../../store/user/user';
import Header from '../../../../common/Header/Header';
import Loader from '../../../../common/Loader/Loader';
import {
  Button,
  Tabs,
  ContainedInput,
  UploadButton,
  UploadPdfButton,
} from '../../../../controls';
import UploadFileButton from '../../../../controls/UploadFileButton/UploadFileButton';
import './CreateFA.scss';
import ContactPersonsFA from './parts/ContactPersons';
import DetailsFA from './parts/Details';
import { getStringWithoutSymbols } from '../../../../../helpers';
import GeneralFA from './parts/General';
import { states } from '../../../../../constants/main';
import UserModalFA from './parts/CreateOrEditUserModal';
import {
  createFreightAgent,
  updateFreightAgentInfo,
} from '../../../../../store/freightAgent/freightAgent';

const CreateFA: FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [generalOpened, setGeneralOpened] = useState(true);
  const [detailsOpened, setDetailsOpened] = useState(true);
  const [isAnyFileUploded, setIsAnyFileUploded] = useState(false);
  const [addressState, setAddressState] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(null);
  const [contactPersonsOpened, setContactPersonsOpened] = useState(false);
  const [editUserPrevData, setEditUserPrevData] = useState(null);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender, setGender] = useState(null);
  const [birth, setBirth] = useState(null);
  const [avatar, setAvatar] = useState<any>({
    file: null,
    temp: null,
  });

  const [w9, setW9] = useState({
    alias: 'w9',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [liabilityInsurance, setLiabilityInsurance] = useState({
    alias: 'liability_insurance',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const [operatingPermit, setOperatingPermit] = useState({
    alias: 'operating_permit',
    file: null,
    title: '',
    delete: 0,
    preview: null,
    fullpath: null,
  });

  const onSubmit = async (data: any) => {
    const id = localStorage.getItem('owner_id');

    const docs = [];

    if (w9.file) {
      docs.push(w9);
    }

    if (liabilityInsurance.file) {
      docs.push(liabilityInsurance);
    }

    if (operatingPermit.file) {
      docs.push(operatingPermit);
    }

    if (id) {
      setSubmitLoading(true);

      const dataForUpdate: any = {
        id: id,
        title: data.fa_name,
        info: data.fa_info,
        mc_authority: data.mc_authority,
        tax_id: data.tax_id,
        dot_id: data.dot,
        scac: data.scac_code,
        address: {
          address: data.address,
          city: data.city,
          state: addressState,
          zip: data.zip,
          fax: getStringWithoutSymbols(data.office_fax),
          cell_phone: getStringWithoutSymbols(data.cell_phone),
        },
        billing_address: {
          address: data.billing_address,
          city: data.billing_city,
          state: billingAddressState,
          zip: data.billing_zip,
          email: data.payable_email,
          phone: getStringWithoutSymbols(data.payable_phone),
        },
        user: {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
        },
      };

      if (docs.length) {
        dataForUpdate.documents = docs;
      }

      if (avatar.temp) {
        dataForUpdate.logo = {
          file: avatar.temp,
          delete: 0,
        };
      }

      dispatch(createFreightAgent(dataForUpdate)).then(res => {
        if (res.type === 'createFreightAgent/fulfilled') {
          toast.success('The Freight Agent was successfully created!');
          navigate('/users-management', {
            state: {
              tab: 'freight_agents',
            },
          });
        } else {
          setSubmitLoading(false);
        }
      });
    }
  };

  return (
    <>
      <Loader loading={false} />

      <div className="createDriver mainContainer">
        <div className="createDriver__container">
          <div className="createDriver__body">
            <div className="createDriver__userForm">
              <div className="createDriver__header">
                <div className="createDriver__title">
                  <h1>Add New Freight Agent</h1>
                </div>

                <Button
                  title="BACK"
                  Icon={<KeyboardBackspaceTwoToneIcon color="primary" />}
                  onClick={() =>
                    navigate('/users-management', {
                      state: {
                        tab: 'freight_agents',
                      },
                    })
                  }
                  className="mapBtn"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="myProfile__details">
              <div className="h2-title">
                <h2 className="h2-title__text">Freight Agent Details</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setDetailsOpened(!detailsOpened)}
                  className={`openBtn ${!detailsOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <DetailsFA
                detailsOpened={detailsOpened}
                isAnyFileUploded={isAnyFileUploded}
                setIsAnyFileUploded={setIsAnyFileUploded}
                setW9={setW9}
                w9={w9}
                setLiabilityInsurance={setLiabilityInsurance}
                liabilityInsurance={liabilityInsurance}
                setOperatingPermit={setOperatingPermit}
                operatingPermit={operatingPermit}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <div className="myProfile__generalInfo">
              <div className="h2-title">
                <h2 className="h2-title__text">General Information</h2>
                <img
                  src={require('../../../../../assets/images/icons/expand-icon.png')}
                  alt="expand"
                  width="24px"
                  height="auto"
                  onClick={() => setGeneralOpened(!generalOpened)}
                  className={`openBtn ${!generalOpened ? 'openBtnOpened' : ''}`}
                />
              </div>

              <GeneralFA
                generalOpened={generalOpened}
                control={control}
                // generalInfo={generalInfo}
                states={states}
                setAddressState={setAddressState}
                setBillingAddressState={setBillingAddressState}
              />

              <hr className="horizontalLine fullHr" />
            </div>

            <Button
              title="SAVE ALL"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              className="saveBtn"
              loading={submitLoading}
            />
          </div>

          <div className="createDriver__userInfo">
            <div className="addNewPhotoBtn">
              <IconButton color="primary" aria-label="upload picture" component="label">
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={e => {
                    if (e && e.target && e.target.files) {
                      const fd = new FormData();
                      fd.append('file', e.target.files[0]);
                      dispatch(fetchUploadFile(fd)).then(res => {
                        if (res.type === 'uploadFile/fulfilled') {
                          setAvatar({
                            file: res.payload.file.fullpath,
                            temp: res.payload.file.filepath,
                          });
                        }
                      });
                    }
                  }}
                />
                <CameraAltOutlinedIcon />
              </IconButton>
            </div>
            <div className="userInfo__avatar">
              <img src={avatar.file} alt="" />
            </div>
            <div className="userInfo__role">Freight Agent</div>
          </div>

          {
            <>
              {openCreateUserModal && (
                <UserModalFA
                  open={openCreateUserModal}
                  setOpen={setOpenCreateUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onAddNewUser}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="ADD"
                  cancelTitle="CANCEL"
                  contentTitle="Add New Contact Person"
                  formType="create"
                  loading={submitLoading}
                />
              )}

              {openEditUserModal && (
                <UserModalFA
                  open={openEditUserModal}
                  setOpen={setOpenEditUserModal}
                  // onChange={onChangeContactPerson}
                  // onSubmit={onEditUserInfo}
                  onChange={() => null}
                  onSubmit={() => null}
                  confirmTitle="EDIT"
                  cancelTitle="CANCEL"
                  contentTitle="Edit Contact Person"
                  defaultData={editUserPrevData}
                  setDefaultData={setEditUserPrevData}
                  formType="edit"
                  loading={submitLoading}
                />
              )}
            </>
          }
        </div>
      </div>
    </>
  );
};

export default CreateFA;
