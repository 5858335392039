export const roundNumber = (
  val: string | number,
  multiplier: number
): number => {
  return Math.round(Number(val) * multiplier) / multiplier;
};

export function numberWithSpaces(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function numberWithoutSpaces(x: any) {
  if (x.length) {
    return x.replace(/\s/g, "");
  }
}

export const isEmptyObject = (obj: object | null) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const getEntityType = (role: any) => {
  switch (role) {
    case "tc-admin":
    case "tc-user":
      return "tc";
    case "fa-admin":
    case "fa-user":
      return "fa";
    case "id-admin":
    case "id-user":
      return "id";
    default:
      return null;
  }
};

export const generateHash = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index;
};

function fetchWithAuthentication(url: string, authToken: string) {
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${authToken}`);
  return fetch(url, { headers });
}

export const getStringWithoutSymbols = (str: string) => {
  if (str && str.length > 0) {
    return str.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "");
  } else return str;
};

export const getMaskedPhone = (str: string) => {
  if (str?.length === 11) {
    return `+${str[0]} (${str.slice(1, 4)}) ${str.slice(4, 7)}-${str.slice(7)}`;
  } else return str;
};

export const displayProtectedImage = async (
  imageId: string,
  imageUrl: string,
  authToken: any
) => {
  const response = await fetchWithAuthentication(imageUrl, authToken);

  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  const imageElement: any = document.getElementById(imageId);
  if (imageElement) {
    imageElement.style.backgroundImage = `url(${objectUrl})`;
  }
};

export const displayImageSrc = async (
  imageId: string,
  imageUrl: string,
  authToken: any
) => {
  const response = await fetchWithAuthentication(imageUrl, authToken);

  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  const imageElement: any = document.getElementById(imageId);
  if (imageElement) {
    imageElement.setAttribute("src", objectUrl);
  }
};

export const getFullpathString = async (url: string, authToken: string) => {
  const response = await fetchWithAuthentication(url, authToken);

  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  return objectUrl;
};

export const getFullName = (firstName: any, lastName: any) => {
  let fullName = "";
  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`;
  } else if (firstName && !lastName) {
    fullName = firstName;
  } else if (!firstName && lastName) {
    fullName = lastName;
  }

  return fullName;
};
