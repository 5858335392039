import { TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './Autocomplete.scss';

interface IUploadButton {
  label: string;
  className?: string;
  items: any;
  name?: string;
  setModalOpen?: any;
  setSearchValue?: any;
  setSharingList?: any;
  sharingList?: any;
  readOnly?: boolean;
}

const AsyncAutocompleteComponent: FC<IUploadButton> = ({
  label,
  className,
  items,
  name,
  setModalOpen,
  setSearchValue,
  setSharingList,
  sharingList,
  readOnly,
}) => {
  const filter = createFilterOptions<any>();
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (value) {
      if (value.fl_number) {
        if (sharingList.length === 0) {
          setSharingList([
            ...sharingList,
            { fl_number: value.fl_number, value: value.value },
          ]);
        } else {
          const sameItem = sharingList.filter(
            (item: any) => item.fl_number === value.fl_number,
          );

          if (sameItem.length === 0) {
            setSharingList([
              ...sharingList,
              { fl_number: value.fl_number, value: value.value },
            ]);
          }
        }
      }

      setValue(null);
    }
  }, [value]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        // setOptions([...topFilms]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <div
      className={`customizedInputContainer ${
        className ? className : ''
      } marginRight autocompleteContainer ${
        !items.length ? 'autocompleteContainer__hidden' : ''
      }`}
    >
      <div className="label">{label}</div>
      <Autocomplete
        disabled={readOnly}
        freeSolo
        handleHomeEndKeys
        selectOnFocus
        clearOnBlur
        value={value}
        disablePortal
        id="combo-box-demo"
        options={items}
        sx={{ width: 300 }}
        renderInput={params => <TextField {...params} />}
        renderOption={(props, option) => {
          if (option) {
            return (
              <li {...props} className="addFeatureContainer autocompleteItem">
                {option.value}
                <span className="addFeatureBtn">+ Add</span>
              </li>
            );
          }
        }}
        getOptionLabel={(option: any) => option.value || ''}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => inputValue === option.value);
          setSearchValue(inputValue);

          return filtered;
        }}
        onChange={(event, newValue) => {
          let isValue = true;
          const isExisting = items.some((item: any) => {
            if (!newValue) {
              isValue = false;
            }
            return newValue === item.value || newValue?.value === item.value;
          });

          if (!isExisting && isValue) {
            if (setModalOpen) {
              setModalOpen(true);
            }
          }
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setValue({
              title: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
      />
    </div>
  );
};

export default AsyncAutocompleteComponent;
