export const states = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },

  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },

  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },

  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },

  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },

  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },

  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },

  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },

  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },

  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

export const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const statuses: any = [
  { value: 'pending', label: 'Pending' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
];

export const types: any = [
  { value: 'back_haul', label: 'Back Haul' },
  { value: 'direct', label: 'Direct' },
  { value: 'drop', label: 'Drop' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'line_haul', label: 'Line Haul' },
  { value: 'pick_up', label: 'Pick Up' },
  { value: 'truck_not_used', label: 'Truck Ordered Not Used' },
];

export const equimpmentTypes: any = [
  { value: 'animal_carrier', label: 'Animal Carrier' },
  { value: 'box_truck', label: 'Box Truck' },
  { value: '48_van', label: '48 Van' },
  { value: '53_van', label: '53 Van' },
  { value: '48_reefer', label: '48 Reefer' },
  { value: '53_reefer', label: '53 Reefer' },
  { value: 'belly_dump', label: 'Belly Dump' },
  { value: 'boating_hauling_trailer', label: 'Boating Hauling Trailer' },
  { value: 'cargo_van', label: 'Cargo Van' },
  { value: 'conestoga', label: 'Conestoga' },
  { value: 'dump_trucks', label: 'Dump Trucks' },
  { value: 'flatbed_stepdeck', label: 'Flatbed/Stepdeck' },
  { value: 'low_boy', label: 'Low Boy' },
  { value: 'maxi_double_flat_trailer', label: 'Maxi/Double Flat Trailer' },
  { value: 'heavy_haul', label: 'Heavy Haul' },
  { value: 'power_only', label: 'Power Only' },
  { value: 'rng', label: 'RNG' },
  { value: 'straight_van', label: 'Straight Van' },
  { value: 'tanker', label: 'Tanker' },
];
export const shipper_types: any = [
  { value: 'tl', label: 'TL' },
  { value: 'ltl', label: 'LTL' },
  { value: 'ftl', label: 'FTL' },
  { value: 'other', label: 'Other' },
];
