import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api, { apiWithBaseUrl } from '../../service/api';

export const fetchUploadFile = createAsyncThunk('uploadFile', async (file: any) => {
  const response = await api.post(`file/upload`, file);
  return response.data;
});

export const searchFLorTitle = createAsyncThunk('searchFLorTitle', async (value: any) => {
  const response = await api.get(`loads/sharing?query=${encodeURIComponent(value)}`);
  return response;
});

export const getTrailersList = createAsyncThunk('getTrailersList', async (data: any) => {
  const response = await api.get(`trailers/${data.type}/${data.id}/list`);
  return response;
});

export const getTrucksList = createAsyncThunk('getTrucksList', async (data: any) => {
  const response = await api.get(`trucks/${data.type}/${data.id}/list`);
  return response;
});

export const createTrailer = createAsyncThunk('createTrailer', async (data: any) => {
  const response = await api.post(`trailers/${data.type}/${data.id}/trailer/create`, data);
  return response;
});

export const createTruck = createAsyncThunk('createTruck', async (data: any) => {
  const response = await api.post(`trucks/${data.type}/${data.id}/truck/create`, data);
  return response;
});

export const createLoad = createAsyncThunk('createLoad', async (data: any) => {
  const response = await api.post(`loads/create`, data);
  return response.data;
});

export const editLoad = createAsyncThunk('updateLoad', async (data: any) => {
  const response = await api.post(`loads/${data.load_id}/update`, data);
  return response.data;
});

export const getEquipmentTypes = createAsyncThunk('getEquipmentTypes', async (data: any) => {
  const response = await api.get(`equipment_types/${data.type}/${data.id}/list`);
  return response.data;
});

export const createEquipmentType = createAsyncThunk('createEquipmentTypes', async (data: any) => {
  const response = await api.post(`equipment_types/${data.type}/${data.id}/equipment_type/create`, data);
  return response.data;
});

export const getTrackingInfo = createAsyncThunk('getTrackingInfo', async (data: any) => {
  const response = await api.get(`load-tracking/${data.trackingId}`);
  return response.data;
});

export const getDriverHistory = createAsyncThunk('getTrackingHistory', async (data: any) => {
  const response = await api.get(`loads/tracking-history/${data.loadId}`);
  return response.data;
});

export const manageFilesForCustomer = createAsyncThunk('manageFilesForCustomer', async (data: any) => {
  let url = `loads/${data.loadId}/manage-files/${data.fileType}/${data.customerId}`;

  if (data.action === 'delete' && data.typeMediaOpen === 'bol-scan') {
    url = `loads/${data.loadId}/manage-files/${data.fileType}`;
  }

  const response = await api.post(url, {
    files: [data]
  });

  return response.data;
});

interface TruckCompanyState {
  carrierInfo: string | null;
  companyName: string | null;
}

const initialState: TruckCompanyState = {
  carrierInfo: null,
  companyName: null,
};

export const loads = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: builder => {
    //Get truck company info
    // builder.addCase(fetchUploadPrivateFile.fulfilled, (state, action) => {});
  },
});

export default loads.reducer;
