import React, { FC, memo, useEffect, useState } from 'react';
import moment from 'moment';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { types } from '../../../constants/main';
import { getFullName } from '../../../helpers';
import { useAppDispatch } from '../../../hooks/global';
import { getTrackingInfo } from '../../../store/load/load';
import { Button, Portal } from '../../controls';
import { ModalDocument } from './parts/ModalDocument/ModalDocument';
import { ButtonComponent } from '../../controls/Button/Button';

import { styled } from '@mui/system';
import './Tracking.scss';

interface ITracking {
  isAuth: any;
}

const Tracking: FC<ITracking> = ({ isAuth }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const [loadInfo, setLoadInfo] = useState<any>(null);
  const [shippers, setShippers] = useState<any>([]);
  const [receivers, setReceivers] = useState<any>([]);
  const [allPending, setAllPending] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [typeMediaOpen, setTypeMediaOpen] = useState<string>('');
  const [selectedObject, setSelectedObject] = useState<unknown>();
  const [customers, setCustomers] = useState<any>([]);
  const [address, setAddress] = useState(' - ')

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  const getStatus = (status: any) => {
    if (status.length) {
      if (status === 'pending') {
        return 'Pending';
      } else if (status === 'in-progress') {
        return 'In Progress';
      } else if (status === 'completed') {
        return 'Completed';
      }
    } else {
      return '-';
    }
  };

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getTrackingInfo({
          trackingId: params.id,
        }),
      ).then((res: any) => {
        if (res.type === 'getTrackingInfo/fulfilled') {
          if (res.payload.resource) {
            setLoadInfo(res.payload.resource);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (loadInfo) {
      if (loadInfo.last_track?.lat && loadInfo.last_track?.lon) {
        const geocoder: any = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(+loadInfo.last_track?.lat, +loadInfo.last_track?.lon);

        geocoder.geocode(
          {
            latLng: latlng,
          },
          function (results: any, status: any) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                setAddress(results[1].formatted_address);
              }
            }
          },
        );

      }

      if (loadInfo.shippers?.length) {
        setShippers(loadInfo.shippers);
        loadInfo.shippers.map((shipper: any) => {
          if (shipper.status !== 'pending') {
            setAllPending(false);
          }
        });
      }
      if (loadInfo.receivers?.length) {
        setReceivers(loadInfo.receivers);
        loadInfo.receivers.map((receiver: any) => {
          if (receiver.status !== 'pending') {
            setAllPending(false);
          }
        });
      }

      if (loadInfo.receivers?.length && loadInfo.shippers?.length) {
        setCustomers([...loadInfo.shippers, ...loadInfo.receivers]);
      }
    }
  }, [loadInfo]);

  useEffect(() => {
    // console.log(address, 'address')
  }, [address])

  const changeOpenModalMap = (type: string, object: unknown) => {
    setIsOpenModal(true);
    setTypeMediaOpen(type);
    setSelectedObject(object);
    const root: HTMLElement | null = document.querySelector('html');
    root ? (root.style.overflow = 'hidden') : null;
    root ? (root.style.paddingRight = '17px') : null;
  };

  const closeOpenModalMap = () => {
    setIsOpenModal(false);
    setTypeMediaOpen('');
    setSelectedObject(null);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  return (
    <div className="homeContainer l-popup trackingContainer" id="home-page">
      {!isAuth && (
        <div className="header">
          <div className="header__logo">
            <img
              src={require('../../../assets/images/main-logo.png')}
              alt="Logo"
              className="header__logoImage"
            />
          </div>
        </div>
      )}

      {loadInfo && (
        <div className="trackingBlockContainer">
          <div className="trackingBlock">
            <div className="trackingBlock__header">
              <div className="trackingBlock__load-number">
                <div className="load-number">{loadInfo?.load_number ?? '-'}</div>
                <div className="load-info">
                  <div className="labelAndValue">
                    <span className="label">Dispatcher:</span>{' '}
                    <span className="value">
                      {loadInfo?.independent_dispatcher?.title}
                    </span>
                  </div>
                  <div className="labelAndValue">
                    <span className="label">Contact Number:</span>{' '}
                    <span className="value">
                      {loadInfo?.independent_dispatcher?.address?.cell_phone ?? '-'}
                    </span>
                  </div>
                  <div className="labelAndValue">
                    <span className="label">Type:</span>{' '}
                    <span className="value">
                      {types.find((item: any) => item.value === loadInfo?.load_type)
                        ?.label ?? '-'}
                    </span>
                  </div>
                  <div className="labelAndValue">
                    <span className="label">Equipment:</span>{' '}
                    <span className="value">
                      {loadInfo?.equipment_type?.title ?? '-'}
                    </span>
                  </div>
                  {/* <div className="labelAndValue labelAndValue-last">
                  <span className="label">Description:</span>{' '}
                  <span className="value">---</span>
                </div> */}
                </div>
              </div>
              <div className="trackingBlock__load-info">
                {/* <div className="load-number">0000000061</div> */}
                <div className="load-info load-info-right">
                  <div className="labelAndValue">
                    <span className="label">Driver Name:</span>{' '}
                    <span className="value">
                      {getFullName(
                        loadInfo?.driver?.profile?.first_name,
                        loadInfo?.driver?.profile?.last_name,
                      ) ?? '-'}
                    </span>
                  </div>
                  <div className="labelAndValue">
                    <span className="label">Phone Number:</span>{' '}
                    <span className="value">
                      {loadInfo?.driver?.address?.cell_phone ?? '-'}
                    </span>
                  </div>
                  <div className="labelAndValue">
                    <span className="label">Truck Number:</span>{' '}
                    <span className="value">{loadInfo?.truck?.number ?? '-'}</span>
                  </div>
                  <div className="labelAndValue labelAndValue-last">
                    <span className="label">Trailer Number:</span>{' '}
                    <span className="value">{loadInfo?.trailer?.number ?? '-'}</span>
                  </div>
                </div>

                <img
                  src={require('../../../assets/images/main-logo.png')}
                  alt="Logo"
                  className="mainLogo trackingBlock__mainLogo"
                />
              </div>
            </div>
            <div className="trackingBlock__body">
              <div
                className="trackingBlock__customer-container"
                key="first-shipper"
              ></div>

              {shippers.length &&
                shippers.map((shipper: any) => {
                  return (
                    <div className="trackingBlock__customer-container" key={shipper.id}>
                      <h1 className="trackingBlock__customer-title">
                        Shipper {shipper.index}
                      </h1>
                      <div className="trackingBlock__customer-name">
                        {getFullName(
                          shipper.customer?.first_name,
                          shipper.customer?.last_name,
                        ) ?? '-'}
                      </div>
                      <div className="trackingBlock__address">
                        {shipper.customer?.address?.address ?? '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        ETA: {shipper?.eta?.date ? moment(shipper.eta.date).format('MM/DD/YYYY hh:mm A') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        Delay: {shipper?.delay?.date ? moment(shipper.delay.date).format('MM/DD/YYYY hh:mm A') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        {shipper.date ? moment(shipper.date).format('MM/DD/YYYY') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        {shipper.date ? moment(shipper.date).format('hh:mm A') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        Weight (lbs): {shipper.weight ?? '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        Phone: {shipper.customer?.address?.cell_phone ?? '-'}
                      </div>
                      <div>
                        <div className="trackingBlock__progressContainer">
                          <div
                            className={`trackingBlock__progress-icon ${
                              loadInfo?.status === 'completed'
                                ? loadInfo?.status
                                : shipper.status
                            }`}
                          ></div>
                          <div className="trackingBlock__progress-status">
                            {loadInfo?.status === 'completed'
                              ? getStatus(loadInfo?.status)
                              : getStatus(shipper.status)}
                          </div>
                        </div>
                        <div className="trackingBlock__bolsContainer">
                          <span
                            className={`btn-modal ${
                              shipper?.bols_scans.length > 0 ? '' : 'disabled'
                            }`}
                            onClick={() => changeOpenModalMap('bol-scan', shipper)}
                          >
                            Scan Bols
                          </span>
                          <span
                            className={`btn-modal ${
                              shipper?.freight_pictures.length > 0 ? '' : 'disabled'
                            }`}
                            onClick={() => changeOpenModalMap('data-pack', shipper)}
                          >
                            Pictures of Freight
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {receivers.length &&
                receivers.map((receiver: any) => {
                  return (
                    <div className="trackingBlock__customer-container" key={receiver.id}>
                      <h1 className="trackingBlock__customer-title">
                        Receiver {receiver.index}
                      </h1>
                      <div className="trackingBlock__customer-name">
                        {getFullName(
                          receiver.customer?.first_name,
                          receiver.customer?.last_name,
                        ) ?? '-'}
                      </div>
                      <div className="trackingBlock__address">
                        {receiver.customer?.address?.address ?? '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        ETA: {receiver?.eta?.date ? moment(receiver.eta.date).format('MM/DD/YYYY hh:mm A') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        Delay: {receiver?.delay?.date ? moment(receiver.delay.date).format('MM/DD/YYYY hh:mm A') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        {receiver.date ? moment(receiver.date).format('MM/DD/YYYY') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        {receiver.date ? moment(receiver.date).format('hh:mm A') : '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        Weight (lbs): {receiver.weight ?? '-'}
                      </div>
                      <div className="trackingBlock__other-info">
                        Phone: {receiver.customer?.address?.cell_phone ?? '-'}
                      </div>
                      <div>
                        <div className="trackingBlock__progressContainer">
                          <div
                            className={`trackingBlock__progress-icon ${
                              loadInfo?.status === 'completed'
                                ? loadInfo?.status
                                : receiver.status
                            }`}
                          ></div>
                          <div className="trackingBlock__progress-status">
                            {loadInfo?.status === 'completed'
                              ? getStatus(loadInfo?.status)
                              : getStatus(receiver.status)}
                          </div>
                        </div>
                        <div className="trackingBlock__bolsContainer">
                          <span
                            className={`btn-modal ${
                              receiver?.bols_scans.length > 0 ? '' : 'disabled'
                            }`}
                            onClick={() => changeOpenModalMap('bol-scan', receiver)}
                          >
                            Scan Bols
                          </span>
                          <span
                            className={`btn-modal ${
                              receiver?.freight_pictures.length > 0 ? '' : 'disabled'
                            }`}
                            onClick={() => changeOpenModalMap('data-pack', receiver)}
                          >
                            Pictures of Freight
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div
                className="trackingBlock__customer-container"
                key="last-receiver"
              ></div>
            </div>

            <div className="trackingBlock__indicator">
              <>
              <div className="trackingBlock__itemIndicator" key="first-indicator">
                {loadInfo.status === 'pending' && <div className={`trackingBlock__carIcon carIcon-start`}>
                  <img
                    src={require('../../../assets/images/icons/tracking-car.png')}
                    alt="tracking-icon"
                  />
                  <span className="addressPopup addressPopupStart">{address}</span>
                </div>}

                <div className={`trackingBlock__circles trackingBlock__circlesDouble`}>
                  {[1].map((item_: any, index_: number) => {
                    return (
                      <div
                        className={`trackingBlock__circle ${
                          index_ === 0 || (index_ === 1 && loadInfo.status !== 'pending')
                            ? 'circle-green'
                            : ''
                        }`}
                        key={item_}
                      >
                        <div className="trackingBlock__innerCircle"></div>
                      </div>
                    );
                  })}
                </div>
                <BorderLinearProgress
                  variant="determinate"
                  value={loadInfo.status === 'pending' ? 0 : 100}
                />
              </div>
              <div className="trackingBlock__itemIndicator" key="first-indicator">
                {(loadInfo.status === 'in-progress' && customers[0]?.status === 'pending') && <div className={`trackingBlock__carIcon carIcon-start`}>
                  <img
                    src={require('../../../assets/images/icons/tracking-car.png')}
                    alt="tracking-icon"
                  />
                  <span className="addressPopup">{address}</span>
                </div>}

                <div className={`trackingBlock__circles trackingBlock__circlesDouble`}>
                  {[1, 2].map((item_: any, index_: number) => {
                    return (
                      <div
                        className={`trackingBlock__circle ${
                          (index_ === 0 && loadInfo.status !== 'pending') || (index_ === 1 && loadInfo.status !== 'pending' && customers[0]?.status !== 'pending')
                            ? 'circle-green'
                            : ''
                        } ${index_ === 0 ? 'hiddenCircle' : ''}`}
                        key={item_}
                      >
                        <div className="trackingBlock__innerCircle"></div>
                      </div>
                    );
                  })}
                </div>
                <BorderLinearProgress
                  variant="determinate"
                  value={(loadInfo.status === 'pending' || customers[0]?.status === 'pending') ? 0 : 100}
                />
              </div>
              </>

              {customers.map((item: any, index: number) => {
                const circlesArray = [1];

                if (index !== customers.length - 1) {
                  return (
                    <>
                    <div className="trackingBlock__itemIndicator" key={index}>
                        <>
                          {((customers[index - 1]?.status === 'completed' &&
                            item.status === 'completed' &&
                            (customers[index + 1]?.status === 'pending' ||
                              // customers[index + 1]?.status === 'in-progress' ||
                              !customers[index + 1])) ||
                            (item.status === 'completed' &&
                              customers?.length >= 2 &&
                              (customers[index + 1]?.status === 'pending' ||
                                // customers[index + 1]?.status === 'in-progress' ||
                                !customers[index + 1]))) && (
                            <div className={`trackingBlock__carIcon`}>
                              <img
                                src={require('../../../assets/images/icons/tracking-car.png')}
                                alt="tracking-icon"
                              />
                              <span className="addressPopup">{address}</span>
                            </div>
                          )}

                          {((item.status === 'in-progress') && loadInfo.status !== 'completed') &&
                            index === 0 && (
                              <div className={`trackingBlock__carIcon carIcon-start`}>
                                <img
                                  src={require('../../../assets/images/icons/tracking-car.png')}
                                  alt="tracking-icon"
                                />
                                <span className="addressPopup">{address}</span>
                              </div>
                            )}
                          <div className={`trackingBlock__circles`}>
                            {[...circlesArray].map((item_: any, index_: number) => {
                              return (
                                <div
                                  className={`trackingBlock__circle ${
                                    (item.status === 'completed' || loadInfo.status === 'completed')
                                      ? 'circle-green'
                                      : ''
                                  } hiddenCircle`}
                                  key={item_}
                                >
                                  <div className="trackingBlock__innerCircle"></div>
                                </div>
                              );
                            })}
                          </div>
                          <BorderLinearProgress
                            variant="determinate"
                            value={(item.status === 'completed' || loadInfo.status === 'completed') ? 100 : 0}
                          />
                        </>
                    </div>
                    <div className="trackingBlock__itemIndicator" key={index}>
                        <>
                          {(((customers[index - 1]?.status === 'completed' || !customers[index - 1]) &&
                            item.status === 'completed' &&
                            // (customers[index + 1]?.status === 'pending' ||
                              (((customers[index + 1]?.status === 'in-progress') && loadInfo.status !== 'completed') ||
                              !customers[index + 1]))
                            ) && (
                            <div className={`trackingBlock__carIcon`}>
                              <img
                                src={require('../../../assets/images/icons/tracking-car.png')}
                                alt="tracking-icon"
                              />
                              <span className="addressPopup">{address}</span>
                            </div>
                          )}

                          <div className={`trackingBlock__circles`}>
                            {[...circlesArray].map((item_: any, index_: number) => {
                              return (
                                <div
                                  className={`trackingBlock__circle ${
                                    ((item.status === 'completed' && (customers[index + 1]?.status === 'in-progress') || customers[index + 1]?.status === 'completed') || loadInfo.status === 'completed')
                                      ? 'circle-green'
                                      : ''
                                  }`}
                                  key={item_}
                                >
                                  <div className="trackingBlock__innerCircle"></div>
                                </div>
                              );
                            })}
                          </div>
                          <BorderLinearProgress
                            variant="determinate"
                            value={((item.status === 'completed' && (customers[index + 1]?.status === 'in-progress') || customers[index + 1]?.status === 'completed') || loadInfo.status === 'completed') ? 100 : 0}
                          />
                        </>
                    </div>
                    </>
                  );
                }
              })}

              <>
              <div className="trackingBlock__itemIndicator" key="last-indicator">
                {(loadInfo.status === 'in-progress' && customers[customers.length-1]?.status === 'completed') && <div className={`trackingBlock__carIcon`}>
                  <img
                    src={require('../../../assets/images/icons/tracking-car.png')}
                    alt="tracking-icon"
                  />
                  <span className="addressPopup">{address}</span>
                </div>}

                <div className={`trackingBlock__circles`}>
                  <div
                    className={`trackingBlock__circle ${
                      (loadInfo.status === 'completed' || customers[customers.length-1]?.status === 'completed') ? 'circle-green' : ''
                    } hiddenCircle`}
                  >
                    <div className="trackingBlock__innerCircle"></div>
                  </div>
                </div>

                <BorderLinearProgress
                  variant="determinate"
                  value={(loadInfo.status === 'completed' || customers[customers.length-1]?.status === 'completed') ? 100 : 0}
                />
              </div>
              <div className="trackingBlock__itemIndicator" key="last-indicator">
                {loadInfo.status === 'completed' && <div className={`trackingBlock__carIcon`}>
                  <img
                    src={require('../../../assets/images/icons/tracking-car.png')}
                    alt="tracking-icon"
                  />
                  <span className="addressPopup addressPopupFinish">{address}</span>
                </div>}

                <div className={`trackingBlock__circles`}>
                  <div
                    className={`trackingBlock__circle ${
                      loadInfo.status === 'completed' ? 'circle-green' : ''
                    }`}
                  >
                    <div className="trackingBlock__innerCircle"></div>
                  </div>
                </div>

                <BorderLinearProgress
                  variant="determinate"
                  value={loadInfo.status === 'completed' ? 100 : 0}
                />
              </div>
              </>
            </div>
          </div>
        </div>
      )}
      <Portal>
        {isOpenModal && (
          <ModalDocument
            isOpenModal={isOpenModal}
            typeMediaOpen={typeMediaOpen}
            closeOpenModalMap={closeOpenModalMap}
            selectedObject={selectedObject}
          />
        )}
      </Portal>
    </div>
  );
};

export default memo(Tracking);
