import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/global';
import MapWithMarkers from '../../../../controls/MapWithMarkers';
import { IMrkers, IDelivariPoint, ILoadsList } from "../../interfaces/interface";


const MapWidthMarkers: FC = () => {
  const { loadsList, loading } = useAppSelector(state => state.loads);
  const [markers, setMarkers] = useState<IMrkers[]>([]);

  useEffect(() => {
    
    if (loadsList && loadsList.data && loadsList.data.resource) {

      //================ for receivers =========================
      loadsList.data.resource.data.forEach((load: ILoadsList) => {
        setMarkers([]);
        load.receivers.forEach((res: IDelivariPoint) => {
          const newMarker = {
            truck: load?.truck?.number ? load?.truck?.number : ' - ',
            treiler: load?.trailer?.number ? load?.trailer?.number : ' - ',
            name: load.driver
              ? `${load.driver.profile.first_name} ${load.driver.profile.last_name}`
              : ' - ',
            city: res?.customer?.address?.city ? res.customer.address.city : ' - ',
            load: load.load_number ? load.load_number : " - ",
            lat: res?.customer?.address?.lat ? res.customer.address.lat : ' - ',
            lng: res?.customer?.address?.lon ? res.customer.address.lon : ' - ',
            date: res.date ? res.date : "",
            id: res?.id ? res.id + '-receiver' : " - ",
            phone: res?.customer?.address?.phone ? res.customer.address.phone : ' - ',
            status: load.status ? load.status : " - ",
          };
          if (res?.customer?.address?.lat && res?.customer?.address?.lon !== null) {
            setMarkers(markers => (markers = [...markers, newMarker]));
          }
        });
      });

      //================ for shippers =========================
      loadsList.data.resource.data.forEach((load: ILoadsList) => {
        load.shippers.forEach((ship: IDelivariPoint) => {
          const newMarker = {
            truck: load?.truck?.number ? load?.truck?.number : ' - ',
            treiler: load?.trailer?.number ? load?.trailer?.number : ' - ',
            name: load.driver
              ? `${load.driver.profile.first_name} ${load.driver.profile.last_name}`
              : ' - ',
            city: ship?.customer?.address?.city ? ship.customer.address.city : ' - ',
            load: load.load_number ? load.load_number : " - ",
            lat: ship?.customer?.address?.lat ? ship.customer.address.lat : ' - ',
            lng: ship?.customer?.address?.lon ? ship.customer.address.lon : ' - ',
            date: ship.date ? ship.date : "",
            id: ship.id ? ship.id + '-shipper' : " - ",
            phone: ship?.customer?.address?.phone ? ship.customer.address.phone : ' - ',
            status: load.status ? load.status : " - ",
          };
          if (ship?.customer?.address?.lat && ship?.customer?.address?.lon !== null) {
            setMarkers(markers => (markers = [...markers, newMarker]));    
          }
        });
      });
    } else if (
      loadsList &&
      loadsList.data &&
      loadsList.data?.resource?.data?.length === 0
    ) {
      setMarkers([]);
    }
  }, [loadsList]);

  return (
    <>
      {!loading && (
        <MapWithMarkers
          zoom={7}
          markers={
            loadsList && loadsList.data && loadsList.data?.resource?.data?.length > 0
              ? markers
              : []
          }
          location
        />
      )}
    </>
  );
};

export default MapWidthMarkers;
