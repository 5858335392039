import { MenuItem, Select } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ContainedInput } from '..';
import './Selection.scss';

interface IUploadButton {
  label: string;
  className?: string;
  items: any;
  name?: string;
  setValue?: any;
  defaultValue?: any;
  readOnly?: boolean;
  control?: any;
}

const Selection: FC<IUploadButton> = ({
  label,
  className,
  items,
  name,
  setValue,
  defaultValue,
  readOnly,
  control,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [valueForReadOnly, setValueForReadOnly] = useState(null);

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
      const labelFromItems = items.find((item: any) => item.value === selectedValue);

      if (labelFromItems) {
        setValueForReadOnly(labelFromItems.label)
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue.value) {
        setSelectedValue(defaultValue.value);
      } else {
        setSelectedValue(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    readOnly ? (
      <ContainedInput
      label={label}
      className="marginRight inputFlexOne"
      control={control}
      readOnly
      defaultValue={valueForReadOnly}
    />
    ) : (<div className={`customizedInputContainer ${className ? className : ''}`}>
      <div className="label">{label}</div>

      <Select labelId="select-label" value={selectedValue} onChange={handleChange} disabled={readOnly}>
        {items?.map((item: any) => {
          return (
            <MenuItem
              value={item.value}
              key={name ? `${item.value}-${name}` : item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>)
  );
};

export default Selection;
